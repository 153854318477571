import ItemImages from 'src/store/dreamdiner/data/demoitemimages'

export default {








	general: {
    yes: 'כן',
		no: 'לא',
    yesNo: 'כן או לא',
		add: 'הוסף',
		addCredits: 'הוסף קרדיטים',
		color: 'צבע משוייך',
    colorBg: 'צבע רקע',
		assigned_icon: 'אייקון משוייך',
		assigned_items: 'פריטים משוייכים',
		back: 'חזור',
		confirm: 'אשר',
		continue: 'המשך',
		cancel: 'בטל',
		clear: 'נקה',
		close: 'סגור',
		day: 'יום',
		delete: 'מחק',
		duplicate: 'הכפל',
		ends: 'מסתיים:',
		finish: 'סיים',
		from: 'מ-',
		loading: 'טוען...',
		mobile: 'מספר נייד',
		name: 'שם',
		next: 'הבא',
		none: 'ללא',
		price: 'מחיר',
		verify: 'אמת ',
		optional: 'אופציונלי',
		save: 'שמור',
		send: 'שלח',
		restart: 'התחל מחדש',
		resetAll: 'נקה הכול',
    start: 'התחל',
		select: 'בחר',
    selectDate: 'בחר תאריך',
		starts: 'מתחיל:',
		title: 'נושא',
		to: 'עד-',
		update: 'עדכן',
    learnMore: 'מידע נוסף',
		assigned_itemsCount: 'מספר פריטים: {count}',
		no_results: 'אין תוצאות',
		restore: 'שחזר',
    daily: "שימושי",
		system: "מערכת",
		noItemsAssigned: 'לא נמצאו פריטים משוייכים',
		chooseSearchMethod: 'בחר אופן חיפוש',
		chooseSearchMethodFull: 'כולל את המילה...',
		chooseSearchMethodStart: 'מתחיל ב-',
		searchItem: 'חפש פריט',
		saveInfo: 'שמור שינויים שבוצעו',
		resetInfo: 'שחזר',
		overviewBtnSettings: 'מידע והגדרות העסק',
		overviewBtnActivity: 'שעות פעילות העסק',
		accordionExpansionLearnMoreTitle: 'קרא עוד אודות נושא זה',
    downloadAppWaiterText: "שם המשתמש לכניסה יהיה מספר הנייד של המלצר",
		downloadAppStaffText: "שם המשתמש לכניסה יהיה מספר הנייד של איש הצוות",
    active: 'פעיל / אקטיבי',
    step01: 'שלב 1',
		step02: 'שלב 2',
		step03: 'שלב 3',
		step04: 'שלב 4',
		step05: 'שלב 5',
		step06: 'שלב 6',
		step07: 'שלב 7',
    option_01: 'אפשרות 1',
    option_02: 'אפשרות 2',
    option_03: 'אפשרות 3',
    option_04: 'אפשרות 4',
    doThis: 'עשו כך',
	},





	app: {
		headsup: 'שים לב!',
		// loading: 'טוען...',
		errorMessage: 'משהו השתבש...',
		errorSaveMessage: 'בעיה בשמירת נתונים. הוזן משהו לא תקין...',
		never: '[No data yet]',
		empty: 'אין תוצאות',
		autocomplete: 'בחר או התחל הקלדה',
		phonePlaceholder: 'ספרות בלבד',
		phoneHint:
			'ספרות בלבד (0-9), ללא מקפים (-), ללא רווחים, ללא נקודות (.) וללא /. מקס. 20 תווים',
		verified: 'מאומת',
		fail: 'אופס. משהו השתבש',
		registeredSupervisorMobileExists:
			'ישנו כבר מנהל הרשום במערכת עם המספר הזה',
		registeredWaiterMobileExists:
			'ישנו כבר מלצר הרשום במערכת עם המספר הזה',
		registeredStaffMobileExists:
			'ישנו כבר איש צוות הרשום במערכת עם המספר הזה',
		itemSpecialPriceAlert:
			'בדוק מחיר מיוחד לפריט זה. תאריך התפוגה בעבר. מחק אותו ונסה שנית',
    itemInvalidParams:
			'חלק מהנתונים אינם חוקיים או חסרים. אנא בדוק שוב את הטופס',
			days: {
			everyday: 'כל יום',
			sunday: 'יום ראשון',
			monday: 'יום שני',
			tuesday: 'יום שלישי',
			wednesday: 'יום רביעי',
			thursday: 'יום חמישי',
			friday: 'יום שישי',
			saturday: 'שבת',
		},
		localeSelect: 'בחר שפה',
		timezoneSelect: 'בחר אזור זמן',
		countrySelect: 'בחר מדינה',
		version: 'גרסה: {version}',
		deleteConfirm: 'האם באמת למחוק את {object}?',
		unsavedChanges: 'לסגור את הטופס הזה? כל שינוי שנכתב לא יישמר.',
	},
	auth: {
		emailVerification: {
			title: 'נא לאמת את האימייל שלך',
			subtitle:
				"הזן את האימייל שלך למטה, ואנו נשלח לך אימייל עם קישור אימות. לחץ על הקישור בדוא\"ל כדי להמשיך.",
			resendTitle: 'האם לשלוח מחדש את קישור האימות?',
			resendCta: 'שלח שוב',
			resent: 'אימייל לאימות נשלח בהצלחה',
			success: "זה עתה הסתיים בהצלחה תהליך האימות",
		},
		login: {
			title: 'ברוכים הבאים ל DreamDiner',
			titleWithName: 'שלום לך {name}!',
			subtitle: 'בצע כניסה כדי להתחיל בחוויה',
			confirm: 'כניסה',
			noUser: 'הירשם תחילה בכדי להמשיך',
			unverified:
				'נדרש אימות לאימייל כדי להמשיך.\nהמייל נשלח ל: {email}. לא קיבלת? בדוק את תיקיית הספאם שלך.',
			fail: 'שם משתמש אם סיסמה לא תקינים',
			success: 'ההתחברות עברה בהצלחה',
			email: 'הכנס כתובת אימייל',
			password: 'הזן סיסמה',
			forgot: 'שכחתי סיסמה',
			register: 'אני רוצה להירשם',
			resendVerification: 'שלח שוב מייל לאימות',
			changeUser: 'אתה לא {user}?',
		},
		forgot: {
			title: 'שכחת סיסמה?',
			subtitle:
				'הזן את כתובת הדוא\"ל שלך, ולאחר מכן בדוק את תיבת הדואר הנכנס שלך (או בתיקיית ה-SPAM!) כדי לקבל קישור חדש לאיפוס סיסמה',
			login: 'נזכרתי בסיסמה! חזרה לעמוד כניסה',
			register: 'חזרה לעמוד הרשמה',
			confirm: 'שלח קישור',
			success: 'מייל קישור נשלח בהצלחה',
      profileResetPassword: 'איפוס סיסמה',
			profileResetPasswordSent: 'קישור נשלח לתיבת האימייל שלך',
			errors: { 205: 'No user found.' },
		},
		register: {
			title: 'ברוכים הבאים ל-DreamDiner',
			subtitle: 'בצע הרשמה כדי להתחיל בחוויה',
			email: 'הכנס כתובת אימייל',
			emailConfirm: 'הכנס בשנית כתובת אימייל',
			phone: 'הכנס מספר נייד',
			password: 'הזן סיסמה',
			passwordConfirm: 'הזן בשנית סיסמה',
			name: "מה השם שלך?",
			existingUser:
				'משתמש עם אימייל זהה או מספר נייד שכזה כבר רשום במערכת',
			success:
				'ברוכים הבאים ל- DreamDiner! בדוק דואר נכנס כדי לבצע אימות לאימייל שלך לפני שממשיכים',
			resend: 'שלח מחדש קוד אימות ({counter})',
			fail: 'אופס. משהו השתבש כרגע',
			// submitName: 'השלם',
			confirm: 'הירשם',
			login: 'נרשמתי כבר',
			otpTitle: 'הזן את הקוד',
			otpSubtitle: 'שלחנו את הקוד לאימייל {email}',
			incorrectOTP: 'הקוד שגוי. נסה שוב או שנשלח קוד חדש',
		},
		// verification: {
		// 	welcome: 'שלום שוב, {name}!',
		// 	title: 'Wait for your SMS verification code',
		// 	subtitle: 'Enter the 6-digit code below to sign in',
		// 	termsPrompt: 'termsPrompt',
		// 	termsCta: 'termsCta',
		// 	wrongNumberPrompt: 'SMS was sent to {number}',
		// 	wrongNumberCta: 'Change and resend?',
		// },
	},
	autocompleteInput: { defaultPlaceholder: 'התחל להקליד...' },

	tooltips: {
		infoAdminCode:
			'זו אינה סיסמת הכניסה! מומלץ לשנות קוד זה על בסיס יומי או שבועי',
		tableMasterCode:
			'קוד בררת מחדל לשולחנות שבהם לא מוזן קוד ספציפי',
		infoBusinessWebsite:
			'בעיקר עבור לקוחות בכדי לנווט לאתר שלך בקלות. העתק את קישור כתובת האתר בדיוק כפי שמוצג בדפדפן שלך',
    registrationPhone: 'נדרש בכדי להיכנס לאפליקציה בנייד',
		waitersAssignTables: 'בחירת שולחנות מרובה אפשרית',
		itemKitchens:
			'לשימוש בממשק מטבח: פריט זה יופיע במסוף המטבח הנבחר',
		itemCategories:
			'פריט זה יוצג תמיד תחת הקטגוריות הנבחרות הללו. אפשרית בחירה מרובה של קטגוריות.',
		itemLabels:
			'סיווג כללי של פריט זה. אפשרית בחירה מרובה',
		itemAllergens: 'אפשרית בחירה מרובה',
		itemSymbol: 'קוד 3 תווים לשימוש בממשק מטבח לזיהוי מהיר',
		categoryName: 'בעדיפות, שם בעל מילה אחת',
		menuPrice:
			'קשר פריט זה לתפריטים וקבע מחיר מיוחד לכל אחד. אם לא מוגדר, מחיר ברירת המחדל יינתן',
		settingsCog: 'כל המידע העסקי של וההגדרות',
		activityHours: 'שעות הפעילות היומיות והשבועיות של העסק',
    businessStatusLive: 'אם נסתר, לקוחות יכולים להגיע לעסק רק באמצעות קוד QR או קישור ישיר לכתובת אתר',
		addTemplateBtn: 'הקפד ללחוץ הוסף בטופס שייפתח בצד שמאל',
		colorWaiter: 'כל הפריטים שהוזמנו ממלצר זה יהיו בצבע של המלצר',
		colorTerminal: 'כל הפריטים הקשורים לעמדה זו יקבלו את הצבע של העמדה הזו',
    colorStaff: 'הקצה צבע לאיש צוות זה',
    colorMenu: 'בחר צבע לתפריט זה, לחץ הוסף, וצפה בגרף שבצד ימין',
		addBtnHint: 'Click here to add to your list',
    itemCategoryAlert: 'חובה לקשר לקטגוריה אחת או יותר',
    settingsBlock: "תכונות והגדרות מערכת",


		categories: {
			cat_100: 'בירה',
			cat_101: 'בירה',
			cat_102: 'בירה מהחבית',
			cat_103: 'שתיה קלה',
			cat_104: 'שתיה',
			cat_105: 'תה',
			cat_106: 'קפה',
			cat_107: 'יין',
			cat_108: 'שמפניה',
			cat_109: 'אלכוהול',

			cat_110: 'קרואסון',
			cat_111: 'לחם',
			cat_112: 'מאפה',
			cat_113: 'דגנים',
			cat_114: 'ביצים',
			cat_115: 'ארוחת בוקר',
			cat_116: 'סלט',
			cat_117: 'ירקות',
			cat_118: 'מרק',
			cat_119: 'לקחת',

			cat_120: 'פיצה',
			cat_121: 'פיצה',
			cat_122: 'טוגנים',
			cat_123: 'כריך',
			cat_124: 'סושי',
			cat_125: 'סושי',
			cat_126: 'רול',
			cat_127: 'אטריות',
			cat_128: 'אורז',
			cat_129: 'לילדים',

			cat_130: 'בורגר',
			cat_131: 'על האש',
			cat_132: 'דגים',
			cat_133: 'דגים',
			cat_134: 'עוף',
			cat_135: 'שיפוד',
			cat_136: 'בר-בק-יו',
			cat_137: 'נקניקיה',
			cat_138: 'מאכלי ים',
			cat_139: 'טייק-אווי',

			cat_140: 'פירות',
			cat_141: 'עוגה',
			cat_142: 'עוגה',
			cat_143: 'גלידה',
			cat_144: 'גלידה',
			cat_145: 'סנדיי',
			cat_146: 'מאפין',
			cat_147: 'משהו מתוק',
			cat_148: 'מנה אחרונה',
			cat_149: 'וואפל',

			cat_150: 'ארוחה',
			cat_151: 'הגשה',
			cat_152: 'סכין ומזלג',
			cat_153: 'קלוש',
			cat_154: 'מנת השף',
			cat_155: 'מסעדה',
			cat_156: 'צהריים',
			cat_157: 'מגש',
			cat_158: 'קפיטרייה',
			cat_159: 'סכום',
		}
	},

	business: {
		adminProfile: {
			profileTitle: 'פרופיל משתמש',
			profileSubTitle: 'ניתן לקבוע כאן מידע ונתונים אישיים',
			name: 'שם',
			nameHint: 'הכנס שמך כאן',
			mobile: 'מספר נייד',
			mobileExplanation: 'המספר הנייד שלך ישמש לשם כניסה לאפליקצית האדמין',
			userPhoto: 'תמונה',
			userPhotoExplanation: 'שתהיה תמונה טובה :)',
			changePassword: 'שינוי סיסמה',
			changePassword2: 'לחץ על כפתור האיפוס בכדי לשנות את הסיסמה שלך, ולאחר מכן בדוק את הודעות האימייל הנכנסות שלך. אם לא התקבל, ייתכן שתצטרך לבדוק גם את תיקיית ה-SPAM',
		},
		business: {
			title: 'שם העסק',
			titlePlaceholder: 'הכנס את שם העסק שלך',
      titleDescriptionPlaceholder: 'עוד עסק ב DreamDiner',
			titleWebsitePlaceholder: 'https://www.your-business.com',
			titleEn: 'English Business Name',
			titleEnPlaceholder: 'שם העסק באנגלית בלבד',
			titleEnExplanation: 'כל הודעות הסמס המתקבלים יהיו תחת שם השולח הזה',
			statusDemo: 'DEMO עסק לדוגמה',
			statusLive: "LIVE",
			statusHidden: "מוסתר",
			switchLive: 'העבר למצב LIVE',
			switchHidden: 'LIVE',
			email: 'אימייל של העסק',
			emailSubtitle: "מייל זה יקבל גם עותק של כל ההזמנות",
			emailPlaceholder: 'הכנס את האימייל של בית העסק',
			emailVerifyText: 'OK. אז האימייל של בית העסק זהה לאימייל שלך',
			emailVerifyBtn: 'אמת עכשיו',
			useMyEmail: 'השתמשו במייל כניסה שלי',
			adminPassword: 'קוד גישה למנהל מערכת',
			adminPasswordSubtitle:
				'קוד סיסמה זה משמש כמגן בטיחות בכדי לאפשר רק למשתמשים מורשים עם הסיסמה לבצע משימות שונות כגון החזרים כספיים, מחיקת הזמנות ועוד. ניתן לשנות על בסיס יומי או שבועי',
			enterNewPassword: 'הזן סיסמה חדשה',
			pin: 'קוד אימות מאסטר לקוח',
			pinSubtitle:
				'השתמש בקוד זה כדי לתת ללקוח שמסיבה כלשהי לא ניתן לאמת אותו בנייד. אל תשתמש בקוד זה באופן קבוע ותשנה אותו לעתים קרובות. ברירת המחדל היא 999',
			pinPlaceholder: 'ספרות בלבד',
			// social: 'Online Presence:',
			website: 'אתר בית העסק',
			websitePlaceholder: 'https://...',
			timezone: 'אזור זמן',
			timezoneSubtitle: 'לעתים נדרשת התאמה בהתאם לשעון קיץ ושעון חורף',
			country: 'מדינה',
			currency: 'מטבע',
			tagline: 'תיאור העסק',
			taglineExplanation: 'תיאור תמציתי שיופיע תחת שם העסק באפליקציית הלקוח',
			hours: 'שעות פעילות',
      currentTime: 'זמן נוכחי בעסק: ',
			adminPasswordPlaceholder: 'לפחות 6 תווים, ספרות בלבד',
			adminPasswordRepeatPlaceholder: 'הזן סיסמה שוב',
			businessLogo: 'לוגו עסקי',
			businessLogoSubtitle: "אם יוותר ריק, הלוגו של DreamDiner יוצג כתמונת ברירת המחדל עבור פריטים ללא תמונה שהועלתה.",
			logoSelectDemoBtn: 'בחר לוגו זמני',
      logoSelectSampleBtn: 'בחר תמונה זמנית',
			logoSelectOr: 'או:',
			logoSelectTitle: 'בחר לוגו זמני ',
			logoSelectSubtitle: 'לאחר יצירת העסק, תוכל כמובן להחליף ללוגו אמיתי משלך',
			openinghoursClosed: 'סגור',
			openinghoursAllDay: '24 ש\'',
      addTimeSegment: 'הוסף מקטע זמן',
			addTimeSegmentExplan: 'כל יום חייב להכיל מקטע זמן אחד לפחות. נא לא לחפוף מקטעי זמן ויש להקפיד שהראשון הוא המוקדם ביותר של היום. \nבכדי להוסיף מקטע מוקדם, מחק מקטעים יומיים קיימים וערוך את הראשון שיוגדר כמוקדם ביותר',
			updateMessageSnackBar: 'השינויים עודכנו בהצלחה',
			businessColorTitle: 'ערכת צבע נושא',
			businessColorSubtitle:
				'הצבע שמתאים הכי טוב לאופי העסק והכי קרוב ללוגו העסקי שלך',
			businessLocationTitle: 'מיקום העסק',
			businessLocationSubtitle:
				'נדרש כדי למצוא את העסק שלך באפליקציית הלקוחות המציגה עסקים לפי מיקום',
			businessLatitude: 'קו רוחב:',
			businessLongitude: 'קו אורך:',
			getLocationFromDevice: 'הגדר מיקום בשבילי',
			getLocationAccessDenied: 'בדוק את הגדרות הדפדפן שלך ואפשר גישה למיקום',

      noTrackerEnableTitle: 'התקדמות מעקב אחר לקוחות (בדרך כלל לעסקים שאינם מסעדות)',
      noTrackerEnableDesc: 'אפליקציית הלקוח כוללת רכיב אפשרות מעקב לתהליך הכנת ההזמנה (מוזמן, בתהליך, מוכן, נמסר). אם העסק שלך לא מטפל בתהליך הכנה או שאתה לא רוצה שהלקוחות שלך ייחשפו ללשונית סטטוס זו לאחר ביצוע ההזמנה שלהם, אתה יכול להחליט להסתיר רכיב זה',
      noTrackerSwitchEnabled: 'לקוחות יעקבו אחר תהליך ההכנה (מצב מסעדה)',
			noTrackerSwitchDisabled: 'אפליקציית הלקוח לא תחשוף את כרטיסיית סטטוס ההכנה',

			businessEatInTitle: 'אפשרויות \"אוכל כאן\"',
			EatInSwitchEnabled: 'כפתור \"אוכל כאן\" באפליקציית לקוח גלויה כעת',
			EatInSwitchDisabled: 'הכפתור כרגע מוסתר',
			businessAllowEatInSubtitle:
				'לדוגמה, אם יש לך עסק שמפעיל רק Take Away ומשלוחים, ניתן להחליט להסתיר את הכפתור הזה באפליקציית הלקוח',
			EatInMinimumTitle: 'הזמנת מינימום:',
			EatInMinimum: 'קבע מחיר',
			businessEatInMinimumSubtitle:
				'יש להגדיר ערך כספי מינימלי להזמנות \"אוכל כאן\". הערך חייב להיות >6',

			businessTakeAwayTitle: 'אפשרויות טייק-אווי',
			TakeAwaySwitchEnabled: 'כפתור \"טייק-אווי\" באפליקציית לקוח גלויה כעת',
			TakeAwaySwitchDisabled: 'הכפתור כרגע מוסתר',
			businessAllowTakeAwaySubtitle: 'אם מופעל (ירוק) - אז כפתור \"טייק-אווי\" באפליקציית הלקוחות מופעל בהתאם לשעת ההתחלה והסיום שהוגדרו',
			takeAwayMinimumTitle: 'הזמנת מינימום לטייק-אווי:',
			takeAwayMinimum: 'קבע מחיר',

			businessDeliveryTitle: 'אפשרויות משלוח',
			DeliverySwitchEnabled: 'כפתור \"משלוח\" באפליקציית לקוח גלויה כעת',
			DeliverySwitchDisabled: 'הכפתור כרגע מוסתר',
			businessAllowDeliverySubtitle:
				'אם מופעל (ירוק) - אז כפתור \"משלוח\" באפליקציית הלקוחות מופעל בהתאם לשעת ההתחלה והסיום שהוגדרו',
			deliveryMinimumTitle: 'הזמנת מינימום למשלוח:',
			deliveryMinimum: 'קבע מחיר',

			tablePlannerTitle: 'הגדרות של Table Planner',
			tablePlannerDesc: 'כמה הגדרות בסיסיות בשימוש Table Planner',
      tablePlannerEnableWeb: 'אפשר את Web Planner',
      tablePlannerLinkBtn: 'פתח את הפלאנר בטאב נפרד בדפדפן',
      tablePlannerEnableWebDesc: 'Web Planner יאפשר ללקוחות שלך לבצע הזמנה ישירות משולחן העבודה או הנייד שלהם, ואתה תראה הזמנות אלה באופן מיידי על הלוח הראשי של אפליקציית Table Planner',
      EnableWebSwitchEnabled: 'לקוחות יכולים כעת לבצע הזמנות עצמיות ב-Web Planner',
			EnableWebSwitchDisabled: 'העסק שלך מוסתר מחיפוש באפליקציית Web Planner',

			tablePlannerFirstDay: 'הגדר יום העבודה הראשון בשבוע',
			tablePlannerFirstDaySun: 'יום ראשון',
			tablePlannerFirstDayMon: 'יום שני',
			tablePlannerFirstDayDesc: "מגדיר באיזה יום יתחיל לוח ההזמנות",
			tablePlannerIntervals: 'מרווחי הלוח הראשי',
			tablePlannerIntervalsDesc: "הגדר את מרווחי הלוח הראשי",
			tablePlannerMainboardDefaultView: 'תצוגת בררת מחדל',
			tablePlannerMainboardDefaultViewDesc:
				'כשהלוח הראשי מוצג, מהי תצוגת בררת המחדל',
			tablePlannerReservationDefaultTime: 'בררת מחדל למשך הזמנה',
			tablePlannerReservationDefaultTimeDesc:
				'מגדיר את בררת המחדל של שעת הסיום ההזמנה',
			paymentsPaymeSettingsTitle: 'PayMe Settings',
			paymentsPaymeSettingsTitleDesc:
				'הגדרות נדרשות לשם קבלת תשלומים',
			paymentsPaymeSettingsTitle: 'הגדרות PAYME',
			paymentsPaymeSettingsIdPlaceholder: 'הזן Seller ID כאן',
			paymentsPaymeSettingsIdTooltip:
				'מספר זה יתקבל לאחר ההרשמה לתשלומים',
			paymentsAcceptApplePayTitle: 'קבל תשלומים ב Apple Pay',
			paymentsSellerIdTitle: 'Seller ID',
			paymentsSellerIdTitleDesc: 'Your PayMe Seller ID',
			paymentsSellerIdDebug: 'Seller ID Debug',
      paymentsSellerIdDebugSample: "Copy and place this in DEV mode only: MPL16444-14303SH9-AJLRRH1F-YU1W0IAH",
			paymentsAcceptBitTitle: 'קבלת תשלום ב BIT',
			paymentsAcceptBitTitleDesc: 'אפשר ללקוחות שלך לבצע את התשלום באפליקציית ביט.\n תחילה יש לאפשר את השימוש בביט בדשבורד של פיימי (Payme Sidebar > Admin > Apps Marketplace) ולאחר מכן להפעיל את המתג כאן.\nשים לב: עמלה של 0.3 ש״ת תתווסף לכל עסקה',
			paymentsBitSwitchEnabled: 'תשלומים בביט יתאפשרו ללקוחות',
			paymentsBitSwitchDisabled: 'תשלומים בביט חסומים',
			paymentsAcceptIcountTitle: 'קבל חשבוניות iCount עבור הלקוחות שלך',
			paymentsAcceptIcountTitleDesc: 'אפשר ללקוחות שלך להוריד את חשבוניות הרכישה שלהם.\n תחילה הפעל את אפליקציית iCount בלוח המחוונים של Payme (סרגל הצד של Payme > Admin > Apps Marketplace) ולאחר מכן הפעל את המתג הזה.\n הערה: 0.35 ש"ח יתווספו לכל עסקה',
			paymentsIcountSwitchEnabled: 'חשבוניות iCount מופעלות עבור הלקוחות שלך',
			paymentsIcountSwitchDisabled: 'חשבוניות iCount מושבתות',
		},
    plans: {
			plansTitle: 'תוכניות שדרוג אפשריות',
			plansSubTitle: 'בחר את התוכנית שמתאימה לעסק שלך ולאופן הפעילות שבו',
			plansItalTitle: 'התוכניות משתנות באופן אוטומטי בהתאם לאחר שהוגדרו כמתואר',
			plansBtnToSite: 'קרא עוד על אודות תוכניות השדרוג',
			learnMoreBtnSite: 'https://dreamdiner.io/he-start/#plans/',

      plansStep1Chip: 'שלב 1',
      plansStep2Chip: 'שלב 2',
      plansStep1Title: 'הגדר קרדיטים או הישאר בחינם',
      plansStep2Title: 'הגדר תשלומים',

      howWorksTitle: 'How it works?',
      howWorksInfo: 'Understand what is best for your business',
      howWorksLine01: 'Any business can upload its menu items and create a digital menu for customers to access using a generated QR code. Additionally, businesses can share links through the admin dashboard.',
      howWorksLine02: 'In order to receive and manage orders, businesses need to have a positive credit account. Credits can be purchased as a pay-as-you-go package or as an unlimited monthly subscription.',
      howWorksLine03: 'To receive payments and allow customers to pay for their orders (including takeaway and delivery orders where upfront payment is required), businesses must register and set up a Stripe account under the DreamDiner platform. In this case, all sales funds are directly deposited into the business account, with Stripe regular fees and a 1.2% commission to the DreamDiner platform deducted.',

      planFreeTitle: 'תפריט',
      planCreditTitle: 'קרדיטים',
      planPaymentsTitle: 'ללא הגבלה',
      planGetPaidTitle: 'קבל תשלום',

      planFreeInfo: 'תוכנית חינמית',
      planCreditInfo: 'יתרת קרדיטים {number}',
      planPaymentsInfo: 'מתחדש בתאריך: {date}',
      planPaymentsNotSubscr: 'לא מנוי',
      planGetPaidInfo: 'הגבר והבטח את המכירות שלך!',

      planFreeLine01: 'A default plan is in place when no other plan is active',
      planCreditLine01: 'A Pay-as-you-go plan where 1 credit = 1 order. Credit packages are one-time payments and your quota will not expire. You can buy an amount of order credits and use them whenever you like ("pay as you go").',
      planPaymentsLine01: 'A monthly subscription recommended for businesses with over 500 orders per month. Subscriptions charge you every month for a fixed amount of order credits. This plan offers your business peace of mind. You won\'t have to worry about constantly topping up credits or dealing with a locked system when your business runs out of credits in the middle of work. Monthly orders are unlimited, and your previous credit balance (if there is one) remains frozen and unaffected',
      planGetPaidLine01: 'Enrolling in the Get Paid program allows clients to make direct payments within the system, eliminating the need for the business to handle payments and ensuring that orders, especially for take-aways and deliveries, are securely paid for',

      whenFreePlan: 'Switched automatically when credit balance reaches 0 (Zero) and no subscription is active',
      whenCreditPlan: 'Automatically is set when the credit balance is positive (>1) and the subscription is not active',
      whenPaymentsPlan: 'This plan is automatically activated upon subscription, and the credit balance remains unaffected',
      planGetPaidPlan: 'This plan is a powerful addition for businesses with either the Credits or Unlimited plan. It enables client payments and ensures seamless and secure order',

      explanFreePlan: 'This fall-back plan is automatically activated when no other plan is set, and no business credits are available. And still, a free digital menu for up to 200 items to share with your clients is always available',
      explanCreditPlan: 'A simple pay-as-you-go plan means every order made equals one credit. Every time your credit balance reaches the minimum set, top up your credits manually',
      explanGetPaidPlan: 'This  full-featured plan is set automatically once subscribed and by setting a unique button link to your preferred payments provider in order to directly receive payments to your account from all client orders, including  the takeaway and delivery selling options',

      planFreeClient: 'Clients are able to view the menu, but they cannot place orders',
      planCreditClient: 'Clients can place orders as long as your business credit balance is greater than 1',
      planPaymentsClient: 'Businesses can accept an unlimited number of orders per month',
      planGetPaidClient: 'Clients can complete their orders by making payments directly',

      benefitMenu: "תפריט דיגיטלי מלא",
      benefitOrders: "ניהול הזמנות בדשבורד",
      benefitPrepApp: "ניהול הזמנות באפליקצית Prep-App",
      benefitSetPaid: "אדמין מסמן הזמנות כשולמו",
      benefitPayments: "אפשר ללקוחות לשלם ישירות",
      benefitUnlimited: "הזמנות ללא הגבלה",

      price100: '100 Credits = $10 (0.1)',
      price500: '500 Credits = $44 (0.088)',
      price1000: '1000 Credits = $150 (0.15)',
      priceSubs: 'Subscription = $44/month',
      priceGetPaid: 'Fees = Stripe usually charges 2.9% + 30¢ for each successful card charge + 1.2% DreamDiner fee per each order',

      creditTerms: 'Credits are valid for 1 year from purchase date',
      subscriptionTerms: 'Cancel anytime, the subscription remains active until the next renewal date',
      getPaidTerms: 'Cancel anytime by deactivating your linked Stripe account',

      btnGetMoreCredits: 'רכוש עוד קרדיטים',
      btnUnlimited: 'Go Unlimited',
      btnUnlimitedifSubscribed: "ניהול המנוי שלך",
      btnGetPaid: 'הירשם ל GetPaid',
			siteLinkPayme: 'https://dreamdiner.io/he-payment/',

      asterisk01: 'The business accepts payments using its current payment system and manually marks orders as PAID in the orders card or in the PREP-APP',
      asterisk02: 'Depends if business has enrolled to GetPaid',
      },

		credits: {
			buyCreditsTitle: 'Buy credits - Pay as you go',
			buyCreditsText1: '1 Credit = 1 Order',
      buyCreditsTextBtn: 'סיכום רכישות קרדיטים',
			currentBalance: 'Current credit balance: {balance}',
			lastCreditPurchase: 'Last credit purchase: {date}',
			underCreditsBtn: 'adds {credits} credits to your account',
			subscribeTitle: 'Be subscribed - Unlimited use.',
			subscribeText: 'Monthly subscription, cancel anytime.',
			lastRenewal: 'המנוי יתחדש בתאריך: {date}',
      subscrEnds: 'המנוי יסתיים ב: ',
      backToPlansPageBtn: 'חזרה לעמוד תוכניות עסקיות',
			underSubscribeBtn: 'Buys a subscription',
			beforeMinimum:
				'Set an alert when your credit balance drops under a minimum',
			minimumLabel: 'Alert when credits drop below:',
      purchasePageTitle: 'סיכום רכישות קרדיטים',
      purchasePageFreeCredits: 'קרדיטים חינם',
      purchasePageTotalPurchased: 'סה״כ שנרכשו',
      purchasePageTotalOrders: 'סה״כ הזמנות',
      purchasePageCreditBalance: 'יתרת קרדיטים',
		},
    fixPlanPage: {
      title: "Subscribe to Unlimited Plan",
      subTitle: "Peace of mind: no worries about running out of Credits",
      titleIsSubscr: "Subscription Settings",
      btnSubscr: "Get Subscribed",
      subscrStatus: "Subscription status: ",
      subscrStatusActive: "Active",
      subscrStatusCanceled: "Canceled",
      nextPayment: "Next payment date: ",
      activeUntil: "Subscription active until: ",
      subscrInvalid: "Subscription is invalid. Please resubscribe in the Customer Portal",
      btnCustomerPortal: "Visit Your Subscription Customer Portal",
      CustomerPortalExplan: "Manage all your personal data, payment methods, cancellations, renewals, and more here.",
      },
      getPaidPage: {
        title: 'Enroll to receive payments',
        subtitle01: 'Please select your preferred payment provider gateway here and open a connected account under the DreamDiner platform. This will enable clients to pay for their orders, and funds will be deposited into your connected account',
        subtitle02: 'Later, you can visit the Sales page to view your payments dashboard and transactions',
        ifGermanyTitle: "Based on your business location - Germany, choose your payment provider",
        ifIsraelTitle: "Based on your business location - Israel, choose your payment provider",
        btnStripSetupAccount: 'Setup a Stripe Account',
        btnStripCreateAccount: 'Create a Stripe Account',
        btnStripCreateConnected: 'Connected as: bus@bus.com',
        btnSoon: 'Available Soon',
        learnMoreStripeLink: 'Learn more about Stripe Connect',
        learnMoreStripeHref: 'https://stripe.com/en-de/connect',
        learnMorePayMeLink: 'Learn how to set a PayMe account',
        learnMorePayMeHref: 'https://dreamdiner.io/he-payment/',
        stripe: 'Stripe',
        stripeEnabled: 'Stripe payments are enabled!',
        btnGoToSalesPage: 'Visit your Stripe dashboards',
        paypal: 'PayPal',
        payme: 'PayMe',
        sepa: 'SEPA',
        amazon: 'Amazon',
        bitcoin: 'Bitcoin',
        paymentsDisabled: "תשלומים מושבתים",
        countries01: 'Countries available: Worldwide',
        countries02: 'Countries available: USA, Israel',
        countries03: 'Countries available: Europe',
        countries04: 'Countries available: USA, Europe',
          },
    qrPage: {
      title: "How can your clients place their orders?",
      subTitle: "All client orders can be managed later in Dashboard > Orders",
      option01Qr: "QR Code",
      option01QrExplan: "Share or print this QR code wherever your clients can access (front door, table, etc.). Scanning this code will guide the client directly to your business to place an order.",
      option02Web: "Web URL",
      option02WebExplan: "This URL is for client-side orders. Copy and paste it into any browser (desktop or mobile) or attach it to a button on your website. ",
      option02WebExplan02: "Note: This link is ALWAYS active, even if your business IS NOT set to LIVE",
      option02WebExplan03: "Or simply start a new order in a new tab by clicking the button below.",
      option03App: "Client App",
      option03AppExplan: "The DreamDiner Client app allows users to easily find your business through text search or location.",
      option03AppExplan01: "Note: Business is searchable only if set to LIVE",
      option04WL: "Private App",
      option04WLExplan: "A white-label app for your business, available for download on the Apple App Store or Google Play.",
      option04WLExplan01: "Clients have no access to other businesses besides yours",
      option04WLExplan02Yellow: "Subject to approval in App Store and Google Play",
      },
    salesPage: {
      title: 'Manage your Sales Transactions',
      subtitle01: 'Quickly view and control your Stripe payments and payouts dashboards',
      subtitle02: 'Tab a tab to load your current dashboards. Might take some time to load',
      btnStripeLoadDashboards: 'Load Stripe Dashboards',
      subtitle03: 'Tab any line in that table for more info and actions (refunds, transaction details etc.)',
      subtitle04: 'Please set a connected Stripe account to activate the button.',
      getPaidLink: 'Set your business to GetPaid',
      getPaidLink: 'Set your business to GetPaid',
      paymentsTab: 'Payments',
      paymentsTabExplan: 'Shows a list of payments with export, refund, and dispute capabilities',
      payoutTab: 'Payouts',
      payoutTabExplan: 'Shows payout information and allows to perform payouts',
      },

      supervisors: {
        title: 'אחמ\"שים',
        subtitle: 'אחמשים יכולים לגשת לדשבורד הניהול אך עם מגבלות והגבלות מסוימות.',
        description:
				'אחמשים יכולים לבצע משימות שונות בתוך המערכת, כגון סקירת הזמנות, בדיקת סך מכירות, קבלת תשלומים, מתן הנחות ועוד. אחמשים יכולים לגשת לדשבורד הניהול עם גישה מוגבלת לחלק מהתכונות המוענקות רק למנהל המערכת.',
        redLine:
				'',
			redLine_wizard:
				'סעיף זה הוא אופציונלי אך מומלץ להוסיף לפחות אחמש אחד. ניתן להגדיר גם את עצמך.',
			mySupervisors: 'האחמ\"שים שלך',
			addSupervisors: 'הוסף אחמ\"ש',
			supervisorName: "שם האחמ\"ש",
			supervisorEmail: "אימייל של האחמ\"ש",
			moreSubtitle:
				"נתונים נוספים של אחמ\"שים כגון תמונה, לוג כניסה ועוד ניתנים לשינוי בהגדרות העסק",
		},
		tables: {
			title: 'שולחנות',
      subtitle: 'ספק ביעילות את ההזמנות של הלקוחות שלך, המסומנות לפי שם השולחן והצבע שממנו נשלחו.',
			description:
			'לשולחנות יש משמעות במערכת DreamDiner מכיוון שהם סמנים חיוניים להזמנות של לקוחות ולמלצרים המשויכים אליהם.\ אם אין לך שולחנות בעסק, פשוט דלג על שלב זה והמערכת תיצור עבורך אחד מאחורי הקלעים.\nלכל שולחן יש את הייחוד שלו: שם וצבע, מה שמקל על זה לזהות לאיזה שולחן ולקוח לשלוח את ההזמנה, ואיזה מלצר צריך לאסוף אותה.\nמומלץ שם שולחן עם לא יותר מארבעה תווים.\nקוד שולחן (לאחר יצירת העסק): לכל שולחן חייב להיות קוד בן 6 ספרות להבטיח שהלקוח אכן נמצא במקום. קוד זה יכול להיות ייחודי לכל שולחן ושולחן או קוד משותף (מאסטר) לכל השולחנות של העסק. מומלץ להחליף קוד זה לעיתים תכופות.',
			redLine:
      'סעיף זה הוא אופציונלי אך מומלץ. עסק לא חייב שיהיו לו שולחנות.',
			redLine_wizard:
      'סעיף זה הוא אופציונלי אך מומלץ. עסק לא חייב שיהיו לו שולחנות.',
			myTables: 'השולחנות שלך:',
			addTables: 'הוסף שולחן',
			name: "שם השולחן",
			namePlaceholder: "עד 4 תווים",
			seats: 'מספר כיסאות',
			colorNote:
				'בזמן אמת המערכת תתאים את צבע השולחן בהתאם לצבע המלצר שהוקצה לה',
			tablePin: 'קוד שולחן 6 ספרות',
      rbTitle: "סטטוס שולחנות בעסק שלך",
      rbExplan: "בחר באחת מהאפשרויות הבאות כדי לציין את אופן ההתיחסות לשולחנות בעסק שלך.",
      rbOption01Title: "שולחנות וקודים: (מומלץ) לעסק יש שולחנות ויעודכנו קודי השולחן או יעשה שימוש בקוד הראשי עבורם",
      rbOption01TitleClient: "DreamDiner מעודד את הלקוחות לבצע הזמנות ישירות בתחנות ההכנה, ומבטל את הצורך של מלצרים בקבלת הזמנות. מומלץ ללקוחות להזין קוד בן 6 ספרות לאישור נוכחותם בשולחן בעת ​​ביצוע הזמנות אוכל, כמו הזמנות אלו בדרך כלל מוכנים לפני תשלום בתרחיש זה, הלקוח יבחר שולחן וקלט את קוד השולחן לפני הגשת הזמנה או להיות ייחודי לכל שולחן או קוד כללי (מאסטר) עבור כל השולחנות במפעל. מומלץ לשנות את הקוד הזה באופן קבו.",
      rbOption02Title: "אין קודים: יש לנו שולחנות אבל לא צריכים את מערכת הקידוד",
      rbOption02TitleClient: "במקרה זה, הלקוח יבחר רק שולחן ויוכל להגיש לאחר מכן ישירות את ההזמנה",
      rbOption03Title: "אין שולחנות: אין לנו שולחנות בכלל",
      rbOption03TitleClient: "במקרה זה, הלקוח ידלג על דפי בחירת השולחן והקידוד",
      rbOption04Title: "Tables QR Code (Future Feature): We will place the given QR code",
      rbOption04TitleClient: "במקרה זה, הלקוח פשוט יסרוק קוד עבור שם השולחן והקוד",
      alertTableStatusUpdated: "סטטוס שולח עודכן בהצלחה",
      alertTableStatusError: "סטטוס שולח נתקל בשגיאה. אנא נסה שוב",
      tablePinExplanation: "אנא הצב מדבקה או מחוון אחר על השולחן עם קוד זה. אם לא הוקצה קוד שולחן ספציפי, המערכת תשתמש אוטומטית בקוד המאסטר של השולחן. מומלץ לעדכן קודים אלו באופן קבו.",
			tableMasterCodeTitle: 'קוד מאסטר לשולחנות',
			tableMasterCodeSubtitle: 'זה יהיה קוד השולחנות החלופי במידה ולשולחן לא קיים או לא הוזן קוד ספציפי מוגדר',
			tableMasterCodeHint: 'למשל: 456789 (6 ספרות)',
			tableMasterCodeSuccessMessage: 'קוד המאסטר לשולחנות שונה ונשמר בהצלחה',
			tableMasterCodeErrorMessage: 'בעיה בשמירה או בשינוי של קוד המאסטר לשולחנות',
			tableMasterCodePaletteLabel: 'משתמש בקוד מאסטר',
			tablePicker: {
				title: 'שיוך שולחנות למלצרים',
				subTitle: 'בחר מלצר, ולאחר מכן שייך לו שולחן / שולחנות',
				waiters: 'המלצרים שלך',
				tables: 'השולחנות שלך',
				alertNotAssigned: 'ישנם שולחנות שלא משוייכים!',
			},
		},
		waiters: {
			waiter: 'מלצר',
			title: 'מלצרים',
      subtitle: 'מלצרים מוקצים לשולחנות שלך לשליטה טובה יותר על הוצאת מנות מוכנות.',
			description:
				'למלצרים תפקיד מכריע בשירות ויעילות עבודת המסעדה. עם זאת, DreamDiner יכולה לצמצם משמעותית את מספרם ואת התלות של המסעדה בהם.\nזו הסיבה: הזמנות לקוחות מאפליקציות הלקוח נשלחות ישירות לתחנת מטבח, עם תשלום עצמאי לאחר מכן. אפליקציית המלצרים היא בעיקר כלי יעיל שעוזר להם לספק פריטים שהוכרזו כמוכנים ללקוחות הרעבים שמחכים בכיליון עיניים.\nאפליקציית המלצרים מאפשרת גם גישה לתפריט העסק ולבצע הזמנות בעבור לקוחות.\nניתן להקצות מלצרים לשולחנות שלכם, ובכך ניתנת לכל הזמנה ושולחן את צבע המלצר לשליטה טובה יותר ומשלוח הזמנה יעיל.\nהמלצר ייכנס לאפליקציית המלצרים עם מספר הנייד שאושר על ידך כאן.',
        redLine:
				'',
			redLine_wizard:
				'מומלץ לפחות מלצר אחד בעסק.\nשיוך לשולחנות יעשה לאחר יצירת העסק',
			myWaiters: 'המלצרים שלך:',
			addWaiters: 'הוסף מלצר',
			waiterName: "שם המלצר",
			waiterPhoto: "תמונת מלצר",
			paletteTablesTitle: 'שולחנות: ',
			tables: 'שולחנות משוייכים',
			tablePickerBtn: 'שייך שולחנות למלצרים',
			assignAll: 'שייך מלצר זה לכל השולחנות',
			notAssignToTable: 'לא משוייך לאף שולחן',
			openPickerBtn: 'שיוך שולחנות למלצרים',
			moreSubtitle: ' ',
			moreSubtitle_wizard:
				"לידיעה: ניתן לערוך תמונה ועוד בהגדרות העסק שייפתח",
			// btnUpdate: 'Apply',
		},
		kitchens: {
			title: 'אודות תחנות הכנה במטבח (תחנות מטבח)',
      subtitle: 'חלוקת הפריטים לתחנות הכנה מקלה על העומס ומציגה בכל תחנה רק את הפריטים המשויכים אליה',
			description:
				'תחנת עבודה במטבח (\"טרמינל\") היא אזור ייעודי שבו מכינים סוג מסוים של מזון. לדוגמה: \"גריל\", \"פס-קר\", \"פס-חם\" וכו\'.\nניתן להציב אפליקציית מטבח בכל תחנת מטבח, והגדירו את בורר תחנת המטבח בהתאם. הפריטים שהזמנתם מוצגים כעת בדיוק במקום שבו הם אמורים להיות מוכנים במטבח שלכם: קוקטייל שהוזמן יופיע רק בתחנת האפליקציה המוגדרת כ\"בר\" - והמבורגר הבית לתחנת מטבח "גריל" וכו\'. ניתוב זה משפר באופן משמעותי את הדיוק והנוחות של ההכנות למטבח שלך.\nתחנות המטבח הן בלתי מוגבלות. \תחנת המטבח הראשי הוא ברירת מחדל של המערכת ומכיל כברירת מחדל את כל פריטי העסק.',
			redLine:
				'',
			redLine_wizard:
				'Main Terminal הוא תחנת ברירת מחדל של המערכת והוא יציג את כל הפריטים שהוזמנו ללא קשר לתחנות שהוקצו להם.',
			myKitchens: 'תחנות המטבח שלך',
			addKitchens: 'הוסף תחנת מטבח',
			kitchenName: "שם תחנת המטבח",
			// kitchenNumber: 'Terminal ID',
			kitchenPlaceholder: 'עד 3 תווים',
		},
		staff: {
			title: 'צוות הכנה',
      subtitle: 'אנשי הצוות יכולים להיכנס לאפליקציית PREP-APP השולטת בהכנת הפריטים בתחנות המיועדות להם.',
			description:
				'איש צוות במטבח יכול להיות למשל \"שף\", \"סו שף\", \"מנקה\", \"שוטף כלים\" ועוד. איש צוות מטבח מוסמך להיכנס גם לאפליקציית המטבח - שם כל ההכנות מתנהלים ומועברים. \nאם עדיף, הקצה כל חבר לתחנת מטבח: ברמן ל \"בר\", הסו-שף לתחנת מטבח \"הכנות לסלט\" וכן הלאה. אם לא הוקצה, תחנת מטבח \"המטבח הראשי\" הוא תמיד ברירת המחדל של המערכת.\nאיש הצוות יכנס לאפליקציית המטבח עם מספר הנייד שאושר על ידך כאן.',
			redLine:
				'מומלץ לפחות איש צוות אחד בעסק',
			redLine_wizard:
				'מומלץ לפחות איש צוות אחד בעסק',
			myStaff: 'הצוות שלך:',
			addStaff: 'הוסף איש צוות',
			staffName: 'שם איש הצוות',
			staffPhoto: 'תמונת איש צוות',
			staffMobile: 'מספר נייד של איש הצוות',
			kitchen: 'תחנת מטבח בררת מחדל',
			// kitchenCheckbox: 'Set as master chef for this terminal',
			kitchenRoles: 'תפקיד',
			staffTerminalHint: 'אם ריק, ייתכן ויש לחזור ולהוסיף תחנת מטבח אחת לפחות',
			color: 'צבע משוייך',
			moreSubtitle_wizard:
				'הגדרות נוספות ניתן להוסיף ולערוך לאחר יצירת העסק',
			staffSelectTerminal: 'שייך לתחנת מטבח',
			staffSelectMainKitchen: 'מטבח ראשי',
		},
		categories: {
			title: 'קטגוריות',
			subtitle: 'קטגוריות משמשות לקיבוץ פריטים רלוונטיים ולאחר מכן מוצגות בדף הנחיתה של התפריט',
			description:
				'Category groups relevant items and then displays them in the menus, such as Burgers, Starters, Cocktails, and Hot drinks. Your items and dishes can be linked to one or more categories: For example, a Home Burger can be linked to the "Burgers" category, or to the "Beef" category, and to the "Our Specials" category! \nCategories are unlimited but try to be concise. \nYou can choose an icon from a provided set or upload your own icon or image to give your menu the perfect look and feel.',
			myCategories: 'הקטגוריות שלך',
			showIcons: 'הצג אייקונים לקטגוריות',
			addCategories: 'הוסף קטגוריה',
			categoryName: 'שם הקטגוריה',
			checkbox: 'הסתר מכל התפריטים',
      option_01: 'העלאת תמונה או אייקון',
      option_02: 'בחר אייקון מתוך סדרה נתונה (צבע הרקע יהיה כמוגדר בשלב 1)',
			cancelItemCategory:
				'לא ניתן לבטל קישור לפריט אם לפריט אין עוד קטגוריה נוספת מקושרת',
			hasItems: 'מספר פריטים משוייכים לקטגוריה הזו',
			hideFromMenu: 'הסתר קטגוריה זו מכל התפריטים',
			hideFromMenuHidden: 'מוסתר',
			hideFromMenuVisible: 'גלוי',
      iconExplan: 'בחר אייקון מהרשימה שיכול להיות זמני וניתן לשנותו תמיד בהמשך',
		},
		options: {
			title: 'אופציות לפריטים',
      subtitle: 'אופציות הן כל הטוב שאתה מאפשר ללקוחות שלך להתאים אישית את הפריטים שהוזמנו',
			description:
				'אופציות הן התוספות והשינויים שניתן לערוך לכל פריט: התאמות אישיות שאתה מאפשר ללקוחות לבקש בעת הזמנת פריט.\nישנן שני סוגים של תוספות: ,\"אופציה רגילה\" שהיא פשוטה, למשל: \"ללא בצל\", \"הוסף קטשופ\", \"ללארוז לקחת\" , וכו\', בעוד \"אופציות קבוצתיות\" היא קבוצה של אפשרויות שבהן הלקוח יכול לבחור רק בררה אחת. לדוגמה, מידת הכנת בשר הוא Raw, Medium או Well-Done. דוגמה נוספת \"אופציה קבוצתית\" היא מבחר תוספת בודדת: הלקוח יכול לבחור רק אחת - ספגטי, אורז או צ\'יפס.\nכל אופציה יכולה להיות ללא תשלום נוסף או בתוספת מחיר ספציפי למחיר הפריט הכולל. בנוסף, ניתן לקשר פריטים לכמה אופציות בהתאם הצורך.',
			redLine: 'בחר מתוך תבנית נתונה או צור חדש משלך',
			redLine_wizard: 'בחר מתוך תבנית נתונה או צור חדש משלך',
			myExtras: 'אופציות לפריט',
			addExtra: 'הוסף אופציה',
			groupOptionsCount: '{count} אופציות',
			freeNoCharge: 'ללא חיוב',
			groupToggle: 'זוהי אופציה קבוצתית',
			groupToggleDescription:
				'כשהאופציה מוגדרת כקבוצתית, הלקוח יכול לבחור רק אופציה אחת מתוך הסט הנתון',
			optionTitle: 'שם האופציה',
			optionNum: 'אופציה #{num}',
			addGroupOption: 'הוסף אופציה',
		},
		groupOptions: {
			title: 'אופציות קבוצתיות',
      subtitle: 'אופציות קבוצתיות הן קבוצה של אפשרויות שבהן ניתן לבחור רק בחירה אחת מתוך הסט הנתון.',
			description:
				'"אפשרות קבוצתית" היא קבוצה של אופציות שבהן הלקוח יכול לבחור רק אחת. לדוגמה, מידת הכנת בשר הוא Raw, Medium או Well-Done. דוגמה נוספת לאפשרות קבוצתית היא בחירת תוספת למנה: הלקוח יכול לבחור רק אחת - ספגטי, אורז או תפוחי אדמה. כל אופציה בתוך האפשרות הקבוצתית יכולה להיות ללא תשלום או להוסיף להתג מחיר ספציפי שיתווסף לחיר הפריט הכולל',
			redLine: 'בחר מהתבנית הנתונה או הוסף משלך.',
			redLine_wizard: 'בחר מהתבנית הנתונה או הוסף משלך.',
			myGroupOptions: 'האופציות הקבוצתיות שלך',
			addExtra: 'הוסף אופציה קבוצתית',
			groupOptionsCount: '{count} אופציות',
			freeNoCharge: 'ללא חיוב נוסף',
			groupOptionsDrawerTitle: 'שם הקבוצה',
			groupOptionsDrawerExplan: 'שים לב: כבררת מחדל תהיה האופציה הראשונה תמיד בחורה באפליקציית הלקוח, אלא אם יחליט לשנותה.',
			groupOptionsDrawerNum: 'אופציה #{num} (בקבוצה זו)',
			addGroupOption: 'הוסף אופציה לקבוצה זו',
			groupBadge: 'אופציה קבוצתית',
		},
		menus: {
			title: 'תפריטים',
      subtitle: 'תפריטים משמשים להתאמת מחירי פריטים שונים במהלך שעות הפעילות של העסק.',
			description:
				'התפריטים הם דינמיים ותלויי זמן, השולטים במראה הפריטים המוצגים באפליקציה של הלקוח. לדוגמה, תפריט ארוחת בוקר בין השעות 08:00-12:00, תפריט Happy Hour בין השעות 17:00-19:00 וכו\'.\nכל תפריט יציג את הפריטים הרלוונטיים המקושרים לתפריט זה. ניתן להציג פריט ספציפי על פני מספר תפריטים. הפריטים מוצגים בכל תפריט בקטגוריה שהוקצתה להם. לדוגמה, הקוקטייל הביתי שלך יכול להיות מוצג בתפריט Happy Hour, בתפריט Late Night, אך לא לכלול אותו מתפריט ארוחת הבוקר. \nכל פריט יכול להכיל תג מחיר שונה בכל תפריט שבו הוא מוצג. קוקטייל ביתי יכול להיות במחיר 6 בתפריט Happy Hour, אבל 9.5 בתפריט Late Night. אפשרות נוספת היא להגדיר הנחה גלובלית שתחול על כל מחירי הפריטים בתפריט זה.\nציר הזמן של התפריט ייתן לך תצוגה ויזואלית טובה יותר של כל התפריטים הפעילים בשעות שונות של היום.\nהימנע מתפריטים חופפים. \nתפריט ראשי הוא תפריט ברירת המחדל כאשר אין תפריט אחר זמין ומציג את כל הפריטים הגלויים.\nהתפריט הרלוונטי מוצג באפליקציית הלקוחות כאשר העסק \"פתוח\", משמע בין שעות העבודה. כאשר העסק מוגדר כ\"סגור\", מחוץ לשעות העבודה, התפריט העסקי שלך באפליקציית הלקוחות יציג, כברירת מחדל, את התפריט הראשי עם כל הפריטים.',
        redLine:
        'סעיף זה הוא אופציונלי אך מומלץ. עסק לא חייב שיהיו לו תפריטים.',
        redLine_wizard:
        'סעיף זה הוא אופציונלי אך מומלץ. עסק לא חייב שיהיו לו תפריטים.',
			myMenus: 'התפריטים שלך',
			addMenu: 'הוסף תפריט',
			hideMenu: 'הסתר תפריט',
			menuName: 'שם התפריט',
			startTime: 'זמן התחלה',
			endTime: 'זמן סיום',
			hasCategories: 'תפריט זה משוייך לקטגוריות',
			itemsCount: '{count} פריטים',
		},
		items: {
			title: 'פריטים',
      subtitle: 'פריטים מתייחסים לכל המוצרים הזמינים למכירה בתפריט העסק, למשל מנות ומשקאות. ודאו שיהיו מושכים ואיכותיים ככל שניתן!',
			description:
				'הפריטים שלך (למעשה, אלו המנות אבל לא רק, כי הם כוללות גם בקבוק קולה או כוס קפה) הם חלון הראווה שלך באפליקציית הלקוחות. לאחר הגדרת התחנות מטבח, הקטגוריות, התפריטים, התוספות - או דילוג עליהם והגדרת ברירת המחדל של המערכת - הפריט שלך מוכן ליצירה!\nהקצה לפריט שם קליט, תיאור קצר, תמונה יפה ותג מחיר. שדות אחרים הם אופציונליים או חובה - עקוב אחר עצות הקצרות במידת הצורך. \nאלרגנים ותגיות מיוחדות הם גם אינדיקטורים חשובים.\nקוד ייחודי בן שלוש אותיות הוא כלי עזר מועיל באפליקציה של המטבח.\nפריטים מקושרים לתפריטים שלך ומוצגים בכל תפריט תחת הקטגוריות המשויכות. פריט יכול להחזיק במחיר שונה בכל תפריט שהוקצה.\nכל עסק צריך פריט אחד לפחות.',
			myItems: 'הפריטים שלי',
			addItem: 'הוסף פריט',
			hide: 'הסתר מכל התפריטים',
      symbol: "קוד פריט (3 אותיות)",
			itemDescription: 'תיאור (מקס. {length} תווים)',
			image: 'תמונת הפריט / מנה',
			categories: 'שייך פריט זה לקטגוריות הבאות:',
			menus: 'שיוך לתפריטים:',
			labels: 'הוסף תווית: (רשות)',
			allergens: 'הוסף אלרגנים: (רשות)',
			kitchens: 'עמדת הכנה ("טרמינל")',
			kitchenTerminalHint: 'בחר תחנת מטבח',
			kitchenTerminalMainKitchen: 'מטבח ראשי',
			price: 'מחיר:',
			menuPrice: 'מחיר בתפריט:',
			discountPlaceholder: 'ללא מחיר מיוחד',
			regularOptions: 'אופציות רגילות',
			groupOptions: 'אופציות קבוצתיות',
      optionsAlertTooltip: 'This option might have an Error',

			// extrasSelectPlaceholder: 'Start typing...',
			addOption: 'צור אופציה חדשה',
			optionsCount: 'אופציות: {num}',
			groupOptionsCount: 'קבוצתיות: {num}',
			extras: 'אופציות',
			duplicateMessage: 'הפריט שוכפל בהצלחה!',
			linkToMenu: 'הגדר מחיר שונה לכל תפריט מותאם אישית (Main Menu הוא תפריט מערכת ברירת מחדל)',
			linkToMenuWizard: '(שיוך לתפריטים לאחר יצירת העסק)',
			linkToMenuBusiness: '(שיוך לתפריטים לאחר יצירת הפריט)',
			pricesUpdate: 'עדכן מחירים',
			pricesReset: 'שחזר מחירים',
			takeawayAllowTitle: 'האם לאפשר פריט זה גם לטייק-אווי ולמשלוח?',
			takeawayAllowIsAlsoForTA: 'פריט זה אפשרי גם לטייק-אווי וגם למשלוח',
			takeawayAllownotForTA: 'פריט זה רק לאוכלים כאן',
			badgeNotForTA: 'פריט לא לטייק אווי ומשלוח',
			badgeForAll: 'פריט לכל סוגי ההזמנות',
			addCategory: 'צור קטגוריה חדשה',
			archiveItemTitle: 'הסתר פריט זה (לא ייראה באף תפריט)',
			archiveItemSwitchisVisible: 'הפריט במצב גלוי',
			archiveItemSwitchisArchived: 'הפריט במצב ״מוסתר״',

			wizardImagesPopup: {
				title: 'בחר תמונה כדוגמה',
				subtitle:
					"תמיד תוכל לשנות אותה מאוחר יותר בהגדרות הפריט",
				// close: 'Close',
				// select: 'Select',
				// clear: 'Clear',
			},
			specialPrice: {
				price: "מחיר מבצע:",
				date: "תאריך תפוגה:",
        hint: "הקש כאן לקביעת יום ושעת סיום",
				dateTooltip: "קבע תאריך תפוגה למחיר מיוחד זה",
			}
		},
		dashboard: {
			staffCardTitle: 'צוות',
			chefs: 'Logged in Kitchen Staff:',
			waiters: 'Logged in Waiters:',
			emptyStaff: 'None are currently logged',
			onboardingCardTitle: 'Onboarding',
			onboardingCardText: 'Get started the easy way',
      qrBlockTitle: "יצירת הזמנה",
      qrBlockSubBtn: "אפשרויות נוספות",
      qrBlockSubTitle: "4 דרכים שבהן הלקוחות שלך יכולים לבצע את ההזמנות שלהם",
			settingsBlockTitle: 'מבט-על',
			settingsBlockSubTitle: 'כל הגדרות ומידע אודות העסק',
			settingsBlockDynamicTitle: 'מזהה עסק: ',
			settingsBlockButtonTitle: 'עדכן נתונים',
      salesBlockTitle: 'עסקאות מכירה',
			salesBlockSubTitle: 'נהל את חשבון ה Stripe של העסק בקלות וביעילות',
      tipsBlockTitle: "טיפים מלצרים",
      tipsBlockSubTitle: "ריכוזי טיפים למלצרים",
			reportsBlockTitle: 'גרף מכירות',
			reportsBlockSubTitle: 'כל דוחות המכירה והפריטים במבט אחד',
			reportsBlockDynamicTitle: "מכירות היום: ",
			reportsBlockButtonTitle: 'עיין בדוחות',
			appsBlockTitle: 'אפליקציות',
			appsBlockSubTitle:
				'שלח הודעות סמס לצוות אילו אפליקציות להוריד ואיך להתחבר',
			appsBlockDynamicTitle: 'White label: ',
			appsBlockButtonTitle: 'היכנס ושלח',
			staffBlockTitle: 'צוות',
			staffBlockSubTitle: 'כל הצוות שלך: אחמ\"שים, מלצרים ועובדי מטבח',
			staffBlockDynamicTitle: 'כרגע מחוברים: ',
			staffBlockButtonTitle: '(בהכנה)',
			tableplannerBlockTitle: 'הזמנת מקום',
			tableplannerBlockSubTitle:
				'אפליקציה לניהול ההזמנות והשולחנות של העסק',
			tableplannerBlockDynamicTitle: 'הזמנות היום: ',
			tableplannerBlockButtonTitle: '(בהכנה)',
			ordersBlockTitle: 'ניהול הזמנות',
      ordersBlockSubTitle: "מעקב הזמנות בזמן אמת",
			ordersBlockDynamicTitle: 'כרגע בהכנה: ',
			ordersBlockButtonTitle: '(בהכנה)',
			businessTierBlockTitle: 'תוכניות שדרוג',
      businessTierBlockSubTitle: "תוכנית קרדיטים לעסק",
			businessTierBlockDynamicTitle: 'התוכנית שלך: ',
			businessTierBlockButtonTitle: '(בהכנה)',
			OnboardingBlockTitle: 'אונבורדינג: שלב-אחר-שלב',
      OnboardingBtn: 'Onboarding',
			OnboardingBlockSubTitle:
				'מדריך בשלבים שיעזור לך להבין את מרכיבי המערכת והקשר ביניהם',
			OnboardingBlockDynamicTitle: 'מספר שלבים: 12',
			OnboardingBlockButtonTitle: 'התחל את האשף',
      qrCodeClientBlockTitle: 'קוד QR לביצוע הזמנות',
      qrCodePrintInputTitle: 'הוסף כותרת להדפסה (אופציונלי)',
      qrCodeCopiedAlert: 'קישור ה URL הועתק ללוח',
      availableSoon: "יתאפשר בקרוב!",
      credits: "תוכנית נוכחית: קרדיטים",
      free: "תוכנית נוכחית: חינמית",
      monthlySubscribed: "תוכנית נוכחית: מנוי",
      getPaidBlockTitle: "Get Paid",
      getPaidBlockSubTitle: "הירשם לקבלת תשלומים",
      batchSettings: "הגדרות",
      batchUtility: "שימושי",
      ribbonPaymentProviderNotSet: "תשלומים מושבתים",
		},
    overview: {
			title: "מבט-על",
		},
		apps: {
			title: 'Download your Apps',
			subTitle: 'Find here all links and login credentials to work efficiently',
			back: 'Back to {name}',
			appStore: 'App Store',
			getItOn: 'GET IT ON',
			googlePlay: 'Google Play',
			download: 'Download on the',
			appStore: 'App Store',
			headerApp: 'App',
			headerUsage: 'Usage',
			headerLinks: 'Download links',
			headerCreds: 'Authorized users and login credentials',
      qrLinkSubtitle: 'בצע הזמנה בדפדפן',
      qrLinkExplan: 'העסק שלך מוכן לקבל הזמנות עם קוד QR זה או על ידי לחיצה על הכפתור למטה. שתף את קוד ה-QR או כתובת האתר עם הלקוחות שלך',
			kitchen: 'אפליקציית מטבח',
			kitchenUsage:
				'מוצב בכל תחנת מטבח כדי לשלוט בהזמנות חדשות ולקדם הכנות',
			kitchenAppGooglePlay:
				'https://play.google.com/store/apps/details?id=com.dreamdiner.kitchen',
			kitchenQrTitle: 'chefs title',
			kitchenPrintTitle: 'אפליקציית מטבח בגוגל פליי',
			waiters: 'אפליקציית מלצרים',
			waitersUsage:
				'אפשר למלצרים שלך לקבל הזמנות לקוחות ולקבל התראה מהמטבח מתי הפריטים מוכנים להגשה',
			waitersAppGooglePlay:
				'https://play.google.com/store/apps/details?id=com.dreamdiner.waiter',
			waiterQRTitle: 'waiters title',
			waitersPrintTitle: 'אפליקציית מלצרים בגוגל פליי',
			supervisors: 'אפליקציית מנהל או אחמ\"ש',
			supervisorsUsage:
				'כלי יעיל עבורך או עבור כל אחמ\"ש לשליטה בשירות השוטף: הזמנות, קופה, צוות ועוד',
			supervisorsAppGooglePlay:
				'https://play.google.com/store/apps/details?id=com.dreamdiner.admin',
			supervisorsWeb: 'https://dev.client.dreamdiner.io/business/4Cu0vVmfFH',
			supervisorsQrTitle: 'supervisors title',
			supervisorsPrintTitle: 'אפליקציית מנהל בגוגל פליי',
			clients: 'אפליקציית לקוחות',
			clientsUsage:
				'כאן הלקוחות שלך יכולים למצוא אותך: להצגת התפריט שלך או לביצוע הזמנות',
			clientsAppGooglePlay:
				'https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod',
			clientsAppStore:
				'https://apps.apple.com/us/app/gypsy-ace-2023/id6449999003',
			clientsWeb: 'https://dev.client.dreamdiner.io/business/4Cu0vVmfFH',
			clientsQrTitle: 'clients title',
			clientsPrintTitle: 'אפליקציות לקוח',
			label: 'אפלקיציה מותאמת אישית',
			labelUsage:
				'אפליקציית הלקוח הפרטי שלך קיימת ב-Google Play וב-Apple App Store עם פריטי המסעדה שלך בלבד',
			labelAppGooglePlay:
				'https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod',
			labelAppStore: '',
      			// Warning! Correct link is 'https://dev.client.dreamdiner.io/business/direct/4Cu0vVmfFH'
			labelWeb: 'https://dev.client.dreamdiner.io/business/4Cu0vVmfFH',
			labelQrTitle: 'label title',
			labelPrintTitle: 'label title',
			tablePlanner: 'Table Planner',
			tablePlannerUsage:
				'אפליקציה מרובת משתמשים גם לאנדרואיד וגם לאייפון שתאפשר את בקרת ההזמנות שלך במסעדה',
			tablePlannerAppGooglePlay:
				'https://play.google.com/store/apps/details?id=com.dreamdiner.planner',
			tablePlannerAppStore:
				'https://apps.apple.com/us/app/restaurant-seating-planner/id1463277281?ls=1',
			tablePlannerQrTitle: 'table Planner title',
			tablePlannerPrintTitle: 'tablePlanner title',
			userName: 'Name',
			userRole: 'Role',
			userLogin: 'Login',
			userSms: 'SMS',
      invite: 'הזמן את {name}',
      inviteSuccess:
        'WhatsApp עם הזמנה וקישור להורדה נשלח בהצלחה :)',
		},
		reports: {
			title: 'עמוד דוחות',
			startDate: 'יום התחלה',
			endDate: 'יום סיום',
			orders: 'הזמנות',
			sales: 'מגירות',
			refunds: 'זיכויים',
			ratings: 'דירוגים',
			selectY: 'Select Y',
			selectX: 'Select X',
			displayType: 'סוג תצוגה',
			totalSales: 'סה\"כ מכירות',
			totalOrders: 'סה\"כ הזמנות',
			totalRefunds: 'סה\"כ זיכויים',
			totalRatings: 'סה\"כ דירוגים',
      totalTips: "סהכ טיפים",
      tips: "טיפים",
			bar: 'Bar',
			line: 'Line',
			area: 'Area',
			average: 'ממוצע',
			showAverage: 'הצג ממוצע',
			print: 'הדפסה',
			export: 'ייצוא',
			byDays: 'לפי ימים',
			byProducts: 'לפי פריטים',
			byWaiters: 'לפי מלצרים',
		},
    orders: {
      switchOnlyLive: 'הצג הזמנות בתהליך',
      switchOnlyUnpaid: 'הצג לא משולמות',
      labelPaid: 'שולם',
      labelUnPaid: 'לא שולם',
      labelInProgress: 'בתהליך',
      labelDone: 'הסתיים',
      sortBy: 'מיין לפי:',
      perPage: 'בכל עמוד:',
      byNum: 'מספר הזמנה',
      byDate: 'תאריך הזמנה',
      allTime: 'מכל הזמנים',
      downloadPrepApp: 'מנהל המערכת וחברי צוות רשומים מוזמנים להוריד את אפליקציית ההכנה של DreamDiner, וליהנות מאפשרויות ותכונות נוספות',
      downloadPrepAppLink: "https://dreamdiner.io/prep_app/",
      clientRatingItem: 'דירוג הפריט ע״י הלקוח: ',
      clientRatingWaiter: 'דירוג שהמלצר קיבל: ',
      clientTip: 'טיפ למלצר: ',
      clientNoRating: 'לא נמצא דירוג',
      tooltipRefreshOrders: 'ריענון הזמנות',
      tooltipRefreshOrdersUpdates: 'עדכונים לקיימות',
      tooltipRefreshOrdersNew: 'הזמנות חדשות',
      tooltipFullScreen: 'מצב מסך מלא',
      tooltipSwitchInProgress: 'הצג רק הזמנות שבתהליך',
      tooltipSwitchUnPaid: 'הצג רק הזמנות לא משולמות',
      tooltipSwitchItems: 'הצג הזמנה',
      tooltipOrderType: 'סוג ההזמנה',
      tooltipItemNumber: 'מספר פריטים בהזמנה',
      tooltipItemNumberExtra: 'שים לב! לחלק מהפריטים בהזמנה בקשות מיוחדות',


      tooltipBtnInProgress: 'הקלק לציין שהסתיימה',
      tooltipBtnDone: 'הקלק להחזיר הזמנה למצב בתהליך',

      tooltipBtnPaidStatusPaid: 'סמן הזמנה כ״לא שולמה״',
      tooltipBtnPaidStatusUnPaid: 'סמן הזמנה כ״שולמה״',

      tooltipBtnOrderRated: 'הזמנה זו דורגה ע״י הלקוח',
      tooltipBtnOrderRequest: 'בקשה מהלקוח',














      list: {
				num: 'סידורי',
        client: 'לקוח',
        tel: 'טל.',
        type: 'סוג',
        table: 'שולחן',
        sum: 'סכום',
        items: 'פריטים',
        remark: 'הערה',
        payment: 'תשלום',
        id: 'ID',
        time: 'זמן',
        status: 'הכנה'
			},

			tabs: {
				progress: 'בהכנה',
				completed: 'מוכן',
				delivered: 'סופק',
			},
		},
		onboarding: {
			backToOnboardingBtn: 'המשך בתהליך האונבורדינג',
			// obIntro: {
			// 	title: 'Congratulations! Your digital menu is always FREE',
			// 	subtitle:
			// 		"All the information you've put into the wizard has now been integrated into your very own, state of the art, interactive website.",
			// 	text: 'Your customers will now be able to use your website to interact with your business in incredible ways, and use it to help them have an even better experience.',
			// 	cta: 'Click here to see it!',
			// },
			// obLocation: {
			// 	title: 'How awesome is that?',
			// 	subtitle:
			// 		'Your clients can now interact with your business seamlessly through their smartphones.',
			// 	text: 'They can order, choose options for their own dishes, call their waiter, pay for themselves and others at their table and even leave a tip without any staff help!',
			// 	cta: "Wow, That's amazing!",
			// },
			// obImages: {
			// 	title: 'One last surprise',
			// 	subtitle: 'Have some free stuff!',
			// 	text: "Let's add some credits to your business account to get you started smoothly. Welcome To DreamDiner.",
			// 	cta: 'Add {num} credits',
			// },
			// stepper: {
			// 	newBusiness: { label: 'Open a new business' },
			// 	startWizard: { label: 'Start the wizard' },
			// 	menuAndItems: { label: 'Your menu & items' },
			// 	replaceDemoImages: { label: 'Replace demo images' },
			// 	customizeBusinessSettings: {
			// 		label:
			// 			'(Optional) Add or customize all business settings (categories, add items, etc...)',
			// 	},
			// 	firstOrder: { label: 'Your first order' },
			// 	visitMiniSite: { label: 'Visit your mini-site and place an order' },
			// 	getOrder: { label: 'Get your order at your business email' },
			// 	checkOrder: { label: 'Check your order' },
			// 	seeYourOrder: { label: '(Optional) See your order' },
			// 	exploreReports: { label: 'Explore reports' },
			// 	visitReports: { label: '(Optional) Visit reports to see your order' },
			// 	tryKitchen: { label: 'Try Kitchen app' },
			// 	downloadKitchenApp: {
			// 		label:
			// 			'(Optional) Download Kitchen app and manage your order from there!',
			// 	},
			// 	enrollPayments: { label: 'Enroll to get paid' },
			// 	getPayments: {
			// 		label: '(Optional) Checkout how you can get payments',
			// 	},
			// 	startWorking: { label: 'Start working!' },
			// 	businessQR: { label: 'Your business QR code' },
			// 	clientApps: { label: 'Android or iOS Client apps' },
			// 	whitelabel: { label: 'Your whitelabel' },
			// },
			//ODEDA OB set here
			onboarding_cards: {
        // 1
				obQR: {
					title: 'בצע את ההזמנה הראשונה!',
					description: "Scan the QR code with your mobile, or click this card for more options.",
					next_cta: 'הבא',
					prev_cta: 'חזור',
					// label: 'Take me there',
				},

        // 2
				obOrder: {
					title: 'הנה ההזמנה שזה עתה עשית!',
					// subtitle: 'Subtitle not in use here',
					description: "After order has been submitted, see it here on your dashboard. Try changing the Preparation and Payment statuses.",
          label: 'קחו אותי לשם',
					next_cta: 'הבא',
					prev_cta: 'חזור',
					// gps_coordinates_link: 'How to find the GPS coordinates',
				},

        // 3
        obReports: {
					title: 'עיין בדוחות העסק',
					// subtitle: 'Subtitle not in use here',
					description: "Real-time data of all activity at a glance! Even the order you have just made...",
          label: 'הראו לי את הדוחות',
					next_cta: 'הבא',
					prev_cta: 'חזור',
				},

        // 4
        obKitchen: {
					title: 'אפליקציית ניהול ההזמנות וההכנות',
					subtitle: 'למכשירי אנדרואיד',
					description: "(Optional) Download the Preparation App for Android. Your assigned staff members are invited to log in!",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קישור לאפליקציה ולאנשי הצוות',
					// kitchen_app_link: 'Download app on Google Play',
					// loginWithNumber: 'And login with:',
				},

        // 5
        obSettings: {
					title: 'הגדרות העסק ומיקום',
					// subtitle: 'Subtitle not in use here',
					description: "Review all your business settings and update your location",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קחו אותי לשם',
				},

        // 6
				obImages: {
					title: 'החלף תמונות זמניות',
					// subtitle: 'Subtitle not in use here',
					description: "(Optional) If you used the demo images, maybe now replace them with your real images?",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קחו אותי לפריטים שלי להחלפת התמונות',
				},

        // 7
				obOptions: {
					title: 'קשר אופציות לפריטים',
					// subtitle: 'Subtitle not in use here',
					description: "(Optional) Your item options and extras: link them to any needed items",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קחו אותי לפריטים שלי לקישור האופציות',
				},

        // 8
        obMenu: {
					title: 'מחירי פריטים בתפריטים',
					// subtitle: 'Subtitle not in use here',
					description: "(Optional) Set different item prices according to your menus (Skip if you did not open any Menus)",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קחו אותי לפריטים לשינוי מחירים',
				},

        // 9
        obTables: {
					title: 'קודי השולחנות',
					// subtitle: 'Subtitle not in use here',
					description: "Explore the options for presenting tables and their coding on the client side.",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קחו אותי לשינויי הקודים',
				},

        // 10
        obWaiters: {
					title: 'קשר מלצרים לשולחנות',
					// subtitle: 'Subtitle not in use here',
					description: "(Optional) If you have waiters, make sure they are assigned to their respective tables (Click the Assign Tables to Waiters button)",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'קחו אותי למלצרים שלי',
				},

        // 11
        obPlans: {
					title: 'תוכניות שדרוג',
					// subtitle: 'Subtitle not in use here',
					description: "Set your business Credits plan and Get Paid plan",
            next_cta: 'הבא',
            prev_cta: 'חזור',
            label: 'סיים',
					learnMore: 'מידע נוסף כאן:',
					business_models_link: 'הצג לי תוכניות עסקיות',
				},

        // 12
				obCategories: {
					title: '4. Update Category icons',
					// subtitle: 'Subtitle not in use here',
					description: "Set your categories, icons or images, exactly as you want them!",
					next_cta: 'Next',
					prev_cta: 'Back',
					label: 'Take me there',
				},
				// obOrder: {
				// 	title: '8. Time to make an order!',
				// 	subtitle: 'Download app or go to URL',
				// 	description:
				// 		"Try the Client App to make your first order, to see the business you have just opened and your menu items!",
				// 	next_cta: 'Next',
				// 	prev_cta: 'Back',
				// 	label: 'Take me there',
				// 	client_app_link: 'Click here for your client URL',
				// },

      // 13
				obLive: {
					title: 'סטטוס העסק ל LIVE',
					// subtitle: 'Subtitle not in use here',
					description:
            "העסק שלך מוכן? זה הזמן להציג אותו בתוצאות החיפוש עם שינוי המתג על הכרטיס",
            next_cta: 'הבא',
            prev_cta: 'חזור',
					label: 'קחו אותי לשם',
				},

				noshowDialog: {
					title: 'האם לצאת מתהליך האונבורדינג?',
          explan: 'מומלץ מאוד להשלים את אשף האונבורדינג, לפחות פעם אחת, על מנת להגדיר את כל הקישורים והתמונות בצורה נכונה לפונקציונליות מלאה של העסק',
					label: 'תייג כאן כדי לא להציג שוב את האונבורדינג  ',
				},
			},
		},
		sections: {
			dashboard: 'דשבורד',
			info: 'הגדרות',
			hours: 'שעות פתיחה',
			supervisors: 'אחמ\"שים',
			tables: 'שולחנות',
			waiters: 'מלצרים',
			kitchens: 'תחנות הכנה',
			chefs: 'צוות',
			categories: 'קטגוריות',
			menus: 'תפריטים',
			items: 'פריטים',
			extras: 'אופציות לפריטים',
			groupOptions: 'אופציות קבוצתיות',

      infoExplan: 'כל המידע והגדרות העסק',
			hoursExplan: 'הגדרת שעות הפתיחה והסגירה',
			supervisorsExplan: 'ניהול צוות האחמ\"שים',
			tablesExplan: 'הוספת ועריכת השולחנות',
			waitersExplan: 'הוספת מלצרים ושיוכם לשולחנות',
			kitchensExplan: 'הוספת ועריכת תחנות ההכנה במקום',
			chefsExplan: 'הוספת אנשי צוות',
			categoriesExplan: 'הוספת ועריכת קטגוריות הפריטים בתפריט',
			menusExplan: 'עריכת תפריטים לפי שעות הפעילות',
			itemsExplan: 'הוספת ועריכת פריטים בתפריט',
			extrasExplan: 'אופציות שאפשר לשנות בהזמנת פריטים',
			groupOptionsExplan: 'אופציות שאפשר לבחור אחת מבין מבחר',
		},
		toolbar: {
			creditsBannerText:
       'התראה: נותרו לעסק עוד {credits} קרדיטים בלבד. המינומום שהוגדר הוא: {minimum}',
			creditsBannerCta: 'טען עוד קרדיטים לעסק כאן',
			credits: 'יתרת קרדיטים:  {credits}',
			miminum: 'התראה כאשר נותרו פחות מ: {miminum}',
      helpTitle: 'תמיכה',
			helpTooltip: 'התחל צאט תמיכה',
			supportPopupTitle: 'סרוק את קוד ה QR',
			supportPopupOr: 'או פשוט הקלק על הכפתור:',
			supportPopupBtn: 'התחל צאט תמיכה בוואטסאפ',
			supportPopupSupportRemark: 'צוות מרכז התמיכה שלנו זמין מ-8:00 בבוקר עד 16:00, א-ו (UTC+2). אנחנו כאן כדי לענות על כל שאלה הקשורה בפתיחת העסק שלך וכמובן לכל שאלה או עניין לאחר מכן!',
      supportHelpScreenshot: 'אנא עזרו לנו על ידי מתן וצירוף צילום מסך או קליפ קצר המתאר בצורה הטובה ביותר את הבעיה',

			breadcrumbs: {
				home: 'העסקים שלי',
				info: 'נתוני העסק',
				dashboard: 'Dashboard',
				qr: "אפשרויות יצירת הזמנה",
				overview: 'מבט-על',
				apps: 'אפליקציות',
				reports: "גרף ודוח מכירות",
				tips: "ריכוזי טיפים",
				orders: 'ניהול הזמנות',
				onboarding: 'Onboarding',
				supervisors: 'Supervisors',
				tables: 'שולחנות',
				waiters: 'מלצרים',
				kitchens: 'תחנות מטבח',
				chefs: 'צוות',
				categories: 'קטגוריות',
				menus: 'תפריטים',
				items: 'פריטים',
				extras: 'אופציות',
				edit: 'ערוך',
				hours: 'שעות',
				groups: 'אופציות קבוצתיות',
				purchases: 'רכישות',
				planner: 'פלאנר ההזמנות',
        plans: 'תוכניות שדרוג',
				sales: 'עסקאות מכירה',
        credits: 'רכישת קרדיטים',
				subscription: 'מנוי לתוכנית FIX',
				// getPaid: 'מנוי לקבלת תשלומים והפעלה',
				payments: 'הרשמה לתשלומים'
			},
		},
		fromWizard: {
			title: 'העסק שלך "{title}" נוצר בהצלחה!\nנוספו לחשבון העסק 500 קרדיטים חינם (פינה שמאלית עליונה)',
      titleDev: "Your New Business {title} is Ready!\Only 5 credits have been added (top right corner) so please test Free mode and Purchase Credits",
      text: 'צפו לאימייל שhשלח אל: {email} (בדוק ספאם!) עם מידע חשוב.\nהשלב הבא הוא מדריך ידידותי ל-Onboarding שיעזור לך להגדיר את העסק שלך בצורה מושלמת. תהנו!',
			btn: 'אני מוכן להתחיל',
		},
	},

	landing: {
		welcome: 'שלום, {user}. נהדר לראותך שוב!',
		lastUpdated: 'עודכן לאחרונה: {date}',
		beta: 'BETA',
		wizardTitle: 'צור עסק חדש',
		wizardDescription: 'אשף ידידותי ידריך וינחה אותך לאורך כל הדרך',
		demoBusinessDescription: 'העסק לבחון ולהתנסות בכל תכונות המערכת',
    emptyBusinessTitle: "העסק שלך יכול להיווצר כאן ממש בעוד כמה דקות.",
		emptyBusinessSubTitle: 'אשף ידידותי יעזור לך להגדיר את היסודות',
	},
	multiSelect: {
		placeholder: 'בחר אחד או יותר',
		empty: 'הרשימה ריקה',
		skipped: 'דלג (ערך בררת מחדל הוסף)',
		onlyOption: 'כרגע, זו האופציה היחידה האפשרית כאן',
	},
	demoBusiness: {
		demoMode: 'לצורך הדגמה בלבד. לא ניתן לבצע שמירת נתונים',
		showdemo: 'הצג עסק לדוגמה',
		hidedemo: 'הסתר עסק לדוגמה',
	},
	nav: {
		login: 'כניסה',
		logout: 'יציאה',
		user: {
			guest: 'שלום, אורח!',
			btn: 'הפרופיל שלך',
			editProfile: 'ערוך פרופיל',
		},
		business: {
			demoBusinessTitle: 'Demo Business',
			enterBusiness: 'Enter Business',
			demoBusinessBtn: 'Live Demo',
		},
	},
	wizard: {
		dialog: {
			title: 'כרגע חזרת לגרסה השמורה של טיוטת יצירת העסק שלך.',
			subtitle: 'האם תרצה להמשיך איפה שהפסקת בפעם האחרונה?',
			confirm_title: 'זהירות!',
			confirm_subtitle:
				'הפעלה מחדש של מדריך זה תחזיר את כל מה שעשית עד כה ותמחק את כל השינויים שבוצעו עד כה. האם אתה בטוח שאתה רוצה להתחיל מאפס?',
			confirm_cta: 'בטח! אפס הכול',
			confirm_back: 'לא! חזור',
		},
		suggestions: {
			option_01: 'הוסף בקלות מתוך תבנית קיימת',
			option_02: 'הוסף משלך מתבנית ריקה',
			toggle: 'הצג הצעות',
			add: 'הוסף מתבנית',
			addTemplateBtn: 'בחר תבנית זו',
			skipNextBtn: 'דלג כרגע',
		},
		toolbar: {
			saved: 'עודכן ונשמר. בשלב זה כטיוטא בלבד',
      typeRequired: 'שלב זה נדרש עבור כל סוגי העסקים',
			typeChecked: 'שלב זה מומלץ לעסק מסוג זה',
			required: 'שלב זה נדרש',
			mobileToolbarProgressNav: 'Click the circle to navigate',
			titles: {
				intro: 'מדריך יצירת עסק חדש במערכת',
        types: 'סוגים',
				customize: 'שלבי המדריך שלך:',
				info: 'מידע',
				supervisors: 'אחמ\"שים',
				tables: 'שולחנות',
				waiters: 'מלצרים',
				kitchens: 'תחנות מטבח',
				chefs: 'צוות מטבח',
				categories: 'קטגוריות',
				menus: 'תפריטים',
				extras: 'אופציות',
				items: 'פריטים',
				terms: 'תנאי שימוש',
				summary: 'סיכום',
			},
			btns: {
				createBusiness: 'צור את העסק שלי!',
			},
		},
		util: { seeMore: 'הצג הכול: {num}', listEmpty: 'הרשימה עדיין ריקה :-0' },
		intro: {
			title: 'כמה מילים לפני שאנחנו מתחילים\r\n',
			subtitle:
				'השאיפה שלנו היא שתפתח את העסק החדש שלך במהירות וביעילות.\r\nיצירת עסק נעשית בשלבים, ותוכל לבחור אילו שלבים נחוצים לעסק שלך.\r\nבכל שלב יינתנו הצעות מוכנות מראש (למשל: פריט דוגמה, שולחן דוגמה), אותן ניתן לשנות ולעדכן מאוחר יותר כמובן, לאחר יצירת העסק.\r\nהמדריך כתוב בלשון זכר וזאת לשם הפשטות בלבד.',
			redLine: 'אתם עומדים ליהנות מחוויית ה DreamDiner  שלכם. לחצו על \"התחל\" בפינה השמאלית למעלה.',
			title2: "סרטון הסבר קצר בטרם תתחיל החוויה שלך (45 שניות)",
			subtitle2:
				'סרטון הסבר קצר המציג את תהליך יצירת העסק במערכת.',
			redLine2: ' 2 דקות זה כל מה שצריך כדי להבין :)',
		},
		customize: {
			title: 'מהו סוג העסק שלך?',
			subtitle:
      'בחר תבנית עסקית בכדי לעזור למערכת להתאים רק את הפיצ\'רים הרלוונטיים הדרושים לסוג העסק שלך.\nלהתאמה אישית ידנית ולבחינת כלל התכונות, בחר "מותאם אישית"',
			clickNext:
      'בכדי להמשיך, לחץ על הכפתור \"הבא\" בפינה השמאלית העליונה',
      typeHeader:
      'להלן שלבי האשף המומלצים לסוג העסק שלך:',
      explan:
				"כדי לשנות את סוג העסק, פשוט לחץ על חזרה",
			bottom:
      'אל דאגה: לאחר יצירת העסק שלך, ניתן להוסיף לו שוב כל פיצ\'ר מחדש',
      businessTypeOtherExplan: "בסוג זה של עסקים, אתה יכול לסמן או לבטל את הסימון של כל סעיף להתאמה אישית מלאה.",
			businessTypes: {
				truck: { title: 'פוד-טראק', subtitle: '' },
				pub: { title: 'בר / פאב', subtitle: '' },
				fine: { title: 'מסעדה', subtitle: '' },
				small: { title: 'דיינר', subtitle: '' },
				cafe: { title: 'בית קפה', subtitle: '' },
				fast: { title: 'מזון מהיר', subtitle: '' },
				buffet: { title: 'בופה', subtitle: '' },
				popup: { title: 'פופ-אפ', subtitle: '' },
				ghost: { title: 'מטבח רפאים', subtitle: '' },
				delivery: { title: 'טייק-אווי / משלוחים', subtitle: '' },
				kiosk: { title: 'קיוסק', subtitle: '' },
				other: { title: 'מותאם אישית', subtitle: '' },
			},
			skippable: 'אופציונלי',
			points: {
				types: {
					title: 'סוג העסק',
					subtitle: 'הגדר את סוג העסק שלך',
					time: 'חצי דקה',
				},
				customize: {
					title: 'Business Steps',
					subtitle: 'התאמה אישית של העסק שלך',
					time: 'חצי דקה',
				},
				info: {
					title: 'פרטי העסק',
					subtitle: 'שם המסעדה שלך, תיאור קצר, הלוגו, הסיסמה ועוד',
					time: '2 דקה',
				},
				supervisors: {
					title: 'מנהלים (אחמ\"שים)',
					subtitle: 'אשר כניסת מנהל (זכור: אתה הוא מנהל המערכת, אדמין, היחידי)',
					time: '1 דקה',
				},
				tables: {
					title: 'שולחנות',
					subtitle: 'הוסף שולחן אחד לפחות ומספר כיסאות',
					time: '1 דקה',
				},
				waiters: {
					title: 'מלצרים',
					subtitle: 'הוסף מלצרים ושייך אותם לשולחנות',
					time: '1 דקה',
				},
				kitchens: {
					title: 'תחנות מטבח',
					subtitle: 'תחנות ההכנה במטבח שלך. למשל: פס חם, פס קר, בר חיצוני וכד\'',
					time: '30 שנ\'',
				},
				chefs: {
					title: 'צוות המטבח',
					subtitle: 'הצוות שלך: שפים, בר-מנים, שוטפי כלים ועוד',
					time: '1 דקה',
				},
				categories: {
					title: 'קטגוריות',
					subtitle: "הוסף את קטגוריות הפריטים (המנות) שלך",
					time: '30 שנ\'',
				},
				menus: {
					title: 'תפריטים',
					subtitle: 'למשל: תפריט צהריים, תפריט Happy Hour, ועוד',
					time: '2 דקה',
				},
				extras: {
					title: 'תוספות / אופציות למנות',
					subtitle: 'למשל: ללא מלח, להוסיף קטשופ, עשוי WD ועוד ',
					time: '1 דקה',
				},
				items: {
					title: 'פריטים',
					subtitle: 'כאן אפשר כבר לפתוח ולהקים את הפריט / המנה הראשונה שלך',
					time: '3 דקה',
				},
				terms: {
					title: 'תנאים והגבלות',
					subtitle: 'קרא והמשך לפני יצירת העסק שלך',
					time: '1+ דקה',
				},
			},
		},
		business: {
			// title: 'Business Name',
			// titlePlaceholder: 'Your business name',
			// email: 'Business Email',
			// emailSubtitle:
			// 	'Your Business Contact Email is essential for system communications and keeps you updated: important notices and changes, new features, exciting tutorial videos, and much more.',
			// emailPlaceholder: 'Your business email',
			// emailVerifyText: 'OK. Your business mail is the same as your user email',
			// emailVerifyBtn: 'Verify now',
			// useMyEmail: 'Use my login email',
			// pin: 'Admin PIN code',
			// pinSubtitle:
			// 	'This 6 digit code will help authorize special actions in your business',
			// pinPlaceholder: 'Numbers only',
			// social: 'Online Presence:',
			// website: 'Your Business Website',
			// websitePlaceholder: 'http://...',
			// country: 'Country',
			// timezone: 'Timezone',
			// description: 'Business Description',
			// optional: 'optional',
			// verification: 'Verfication needed',
			// currency: 'Currency',
			// hours: 'Business Hours',
			// adminPassword: 'Admin PIN Code',
			// adminPasswordSubtitle:
			// 	'Your PIN is used in many features throughout the system, it can be easily changed on a daily basis, in order to give permissions who can perform and execute various tasks and features. For example: refunding an order, deleting an item, manually logging out a staff member, etc. It is recommended to change this 6-digit password frequently in the Settings.',
			// adminPasswordPlaceholder: '6 digits minimum, letters and numbers',
			// adminPasswordRepeatPlaceholder: 'Repeat password',
			// businessLogo: 'Business Logo',
			// businessLogoSubtitle:
			// 	'Your logo is presented throughout the Client App and in many other places. Your logo is your business mirror - upload a good one :) If left empty - the system will show the DreamDiner logo. Business Logo will also be the default image in all items which do not have a photo or an uploaded image',
			// logoSelectDemoBtn: 'Select Demo Logo',
			// logoSelectOr: 'Or:',
			// logoSelectTitle: 'Select from a given demo logo',
			// logoSelectSubtitle: 'Later, you will replace it with your own logo',
		},

		terms: {
      title0: 'אז איך ממשיכים מכאן?',
			subtitle0: 'בשלב הבא, תתבקש לאשר את העסק שלך טרם יצירתו במערכת. צריך לעשות שינויים כלשהם? אין בעיה; אפשר לחזור ולשנות, כל מה שעשית נשמר. לאחר שנוצר העסק, סרוק את קוד ה-QR שעל המסך בכדי לבצע את ההזמנה הראשונה שלך. מגניב!\nהמערכת תעניק לעסק החדש 500 קרדיטים בחינם לביצוע 500 הזמנות בהם תוכל לבחון את דרימדיינר, טרם תחליט על המודל העסקי להמשך הדרך.\nעקוב אחר מדריך ה Onboarding כדי להפוך את העסק שלך לטוב עוד יותר, ואז אפשר להפוך אותו ל- LIVE ולהתחיל לעבוד!ו',
			title: 'התנאים וההגבלות שלנו',
			description:
				'בעוד רגע מסיימים, ויוצאים לדרך! שלב אחרון לפני שנפתח את העסק הייחודי שלך במערכת DreamDiner אנא קרא בעיון והסכם לנושאים הבאים:',
			redLine: 'תודה על שקראת בעיון ונתת את אישורך בכך בתייגת את כל הסעיפים',
			termsCheckbox: 'קראתי ואני מסכים ל-',
			termsLink: 'מדיניות ותנאים',
			paymentCheckbox:
				'קבלת תשלום ואפשרויות הגדרת תשלום מופיעות בהגדרות',
			idleCheckbox: 'מחיקה של העסק תוך 90 יום אם לא בוצעה פעילות',
		},
		summary: {
			businessCreationTitle: 'האם אפשר ליצור את העסק החדש שלך?',
      businessCreationSubTitle: 'הנה מה שסיפרת לנו על יצירת העסק הבסיסי שלך. אפשר לחזור לכל שלב שאתם צריכים או לעשות זאת לאחר יצירת העסק.',
      businessCreationClickNext: 'בכדי להמשיך, לחץ על הכפתור \"צור את העסק שלי\" בפינה השמאלית העליונה',
      businessCreation3only: 'לשם הפשטות, מוצגים רק 3 האלמנטים הראשונים בכל קבוצה',
			staff: 'צוות',
			supervisors: 'אחמ\"שים',
			waiters: 'מלצרים',
			chefs: 'צוות מטבח',
			equipment: 'המקום',
			kitchens: 'תחנות מטבח',
			tables: 'שולחנות',
			products: 'תפריטים ופריטים (מנות)',
			extras: 'אופציות (תוספות)',
			menus: 'תפריטים',
			categories: 'קטגוריות',
			items: 'פריטים',
			regularOptions: 'אופציות רגילות',
			groupOptions: 'אופציות קבוצתיות',
			extraOptions: '{num} אופציות',
			addRegularOption: 'הוסף אופציה רגילה',
			addGroupOption: 'הוסף אופציה קבוצתית',
			finalize: 'התחל את העסק שלך',
			working: 'יוצרים את העסק שלך',
			patience: 'נא להמתין...',
			saving: {
				info: 'שומר את העסק...',
				supervisors: 'מעדכן אחמ\"שים',
				tables: 'מעדכן שולחנות',
				waiters: 'מעדכן מלצרים',
				kitchens: 'מעדכן תחנות מטבח',
				chefs: 'מעדכן צוות',
				categories: 'מעדכן קטגוריות',
				menus: 'מעדכן תפריטים',
				extras: 'מעדכן אופציות',
				items: 'מעדכן פריטים',
			},
		},
		steps: {
			info: 'מידע',
      types: 'סוגים',
			customize: 'סוגי עסק',
			supervisors: 'אחמ\"שים',
			tables: 'שולחנות',
			waiters: 'מלצרים',
			kitchens: 'מטבח',
			chefs: 'צוות',
			categories: 'קטגוריות',
			menus: 'תפריטים',
			extras: 'אופציות',
			items: 'פריטים',
			terms: 'תנאים',
			summary: 'סיכום',
		},
	},
	validation: {
		required: 'שדה זה נדרש',
		email: 'כתובת אימייל לא תקינה',
		minLength: 'חייב להיות {min} תווים או יותר',
		maxLength: 'חייב להיות {max} תווים או פחות',
		specificLengthDigits: 'חייב להיות בן {number} ספרות',
		sameAsEmail: 'כתובות אימייל לא זהות',
		sameAsPassword: 'סיסמאות לא זהות',
		maxValue: 'חייב להיות פחות מ- {max}',
		minValue: 'חייב להיות יותר מ- {min}',
		toAfterFrom: 'מועד סיום חייב להיות לאחר מועד התחלה',
		isUniquePhone: 'מספר זה כבר נמצא בשימוש כאן',
		isUniqueTitle: 'מספר זה כבר נמצא בשימוש כאן',
		isUniqueSymbol: 'סימן זה כבר נמצא בשימוש כאן',
		url: 'חייב להיות URL תקין',
		mustBeInFuture: 'חייב להיות בזמן עתידי',
		numeric: 'חייב להיות מספר',
		dataNotValid: 'הנתונים שהוזנו אינם חוקיים '
	},
	hours: {
		default: 'בררת מחדל',
		sunday: 'ראשון',
		monday: 'שני',
		tuesday: 'שלישי',
		wednesday: 'רביעי',
		thursday: 'חמישי',
		friday: 'שישי',
		saturday: 'שבת',
	},
	warnings: {
		// veganItem: "צמחוני",
		// spicyItem: "מתובל",
		hot: "חריף",
		organic: "אורגני",
		special: "מנת השף",
		spicy: "מתובל היטב",
		vegan: "טבעוני",
		longPreparation: "הכנה ארוכה",
		glutenFree: "ללא גלוטן",

		crustacean: "סרטנים ופירות ים",
		fish: "דגים",
		egg: "ביצים",
		gluten: "גלוטן",
		celery: "סלרי",
		nuts: "אגוזים",
		milk: "חלב",
		peanuts: "בוטנים",
		sesame: "שוםשום",
		molluscs: "רכיכות",
		lupins: "תורמוסים",
		mustard: "חרדל",
		mushroom: "פטריות",
		corn: "תירס",
		soya: "סויה",
		sulphite: "סולפיט"
	},
	imageCropper: {
		cropperTitle: "העלאת תמונה",
    cropperSubTitle: "אם גדולה מדי, נסה להתאים את הסליידר ואת גודל חיתוך התמונה",
		imageSize: "גודל תמונה נוכחית: ",
		imageQuality: "איכות התמונה: ",
		maxImageSize: "גודל תמונה מקסימלי: ",
		calcOnCrop: "[לחץ ״גזור״]",
		replaceImageBtn: "הוסף / החלף",
		cropImageBtn: "שמור",
		reduceSizeHint: "שנה את גדול התמונה על ידי הקטנת האיכות שלה בערכים 0.01 עד 1.0",
	},
	imagePicker: {
		default: 'גרור לכאן תמונה או\r\nחפש והעלה',
		wizard:
			'העלה תמונה עבור פריט זה',
	},
  kitchenRoles: {
    chef: "שף ראשי",
    sousChef: "םו-שף",
    grillChef: "גריל-מן",
    bartender: "ברמן",
    kitchenAssistant: "עוזר טבח",
    other: "אחר"
  },
  quickGuide: {
    title: "How To?",
    q1: "Manage my orders",
    q1opt01a: "Admin Dashboard > Order Management card",
    q1opt01b: "Here you can promote the whole order to Ready, then set it as PAID",
    q1opt02a: "Use the Prep-App",
    q1opt02b: "Recommended: lots of extra features available to manage orders. Log in using any staff member\'s mobile number.",
    q1opt03a: "By email",
    q1opt03b: "Sent to the business email address specified in Dashboard > Overview > Settings > Business email",

    q2: "Refund an item",
    q2opt01a: "Order active: use the Prep-App",
    q2opt01b: "Choose between receiving a regular refund or opting for an \"On-The-House\" reimbursement. In either case, the client will receive a notification through a popup and a message.",
    q2opt02a: "Order completed: with Payments Provider Dashboard",
    q2opt02b: "Login to your dashboard, find the transaction, click on the line, and send a refund.",

    q3: "How to add a general item?",
    q3ans: "A general item (an item that is not listed on your menu) can be done with the Prep-App > click the order + icon > add general item",
    q4: "How to calculate the waiter's tips?",
    q4ans: "All waiters\' tips are in the Dashboard > Waiters Tips card. Set the waiter and day, or even export a CSV (EXCEL) file",

  },


  explanations: {
    supervisorStep01___Wizard: 'Name and email will be the Supervisor\'s username to log into the admin dashboard',
    supervisorStep01_Business: 'Name and email will be the Supervisor\'s username to log into the admin dashboard',
    supervisorStep02___Wizard: 'The mobile number will be the Supervisor\'s username to log into the PREP-APP (more info later)',
    supervisorStep02_Business: 'The mobile number will be the Supervisor\'s username to log into the PREP-APP (Go to: Dashboard > Apps)',

    tableStep01___Wizard: 'Each table must have a name and the number of seats',
    tableStep01_Business: 'Each table must have a name and the number of seats',
    tableStep02___Wizard: 'Assign a color for this table',
    tableStep02_Business: 'Assign a color for this table',
    tableStep03___Wizard: 'Set a Table Code', //Not in Wizard
    tableStep03_Business: 'Set a Table Code',

    waiterStep01___Wizard: 'Name and mobile number will be the Waiter\'s username to log into the waiter app',
    waiterStep01_Business: 'Name and mobile number will be the Waiter\'s username to log into the waiter app',
    waiterStep02___Wizard: 'All tables - and orders sent from these tables - will be marked with this selected waiter\'s color for easy delivery.',
    waiterStep02_Business: 'All tables - and orders sent from these tables - will be marked with this selected waiter\'s color for easy delivery.',
    waiterStep03___Wizard: '(Optional) The client will be able to view the waiter\'s photo and name after placing an order and on the payment page for leaving a tip.', //Not in Wizard
    waiterStep03_Business: '(Optional) The client will be able to view the waiter\'s photo and name after placing an order and on the payment page for leaving a tip.',
    waiterStep04___Wizard: 'Check which tables will be assigned to this waiter.', //Not in Wizard
    waiterStep04_Business: 'Check which tables will be assigned to this waiter.',

    terminalStep01___Wizard: 'Keep the given name as short and concise as possible',
    terminalStep01_Business: 'Keep the given name as short and concise as possible',
    terminalStep02___Wizard: 'The given color will color all ordered items in the PREP-APP to easily distinguish their assigned station',
    terminalStep02_Business: 'The given color will color all ordered items in the PREP-APP to easily distinguish their assigned station',

    staffStep01___Wizard: 'Name and mobile number will be the Staff Member\'s username to log into the PREP-APP',
    staffStep01_Business: 'Name and mobile number will be the Staff Member\'s username to log into the PREP-APP',
    staffStep02___Wizard: 'The given color will color all ordered items this Staff Member will make for clients in the PREP-APP',
    staffStep02_Business: 'The given color will color all ordered items this Staff Member will make for clients in the PREP-APP',
    staffStep03___Wizard: 'Assign a role and a preparation station',
    staffStep03_Business: 'Assign a role and a preparation station',
    staffStep04___Wizard: 'A photo is optional but useful',
    staffStep04_Business: 'A photo is optional but useful',

    categoryStep01___Wizard: 'Each category must include a name and a default background color',
    categoryStep01_Business: 'Each category must include a name and a default background color',
    categoryStep02___Wizard: 'You have the option to upload your own icon or image or choose from the provided set. Icons and images are always changeable and updatable',
    categoryStep02_Business: 'You have the option to upload your own icon or image or choose from the provided set. Icons and images are always changeable and updatable',

    menuStep01___Wizard: 'Each menu must include a name, a start, and an end time. When you set your items, you can link them to different Menus. However, the ability to set different prices for items on different menus is only enabled after the business is created. If a menu is active during its specified start and end times, the price set for items on that menu will override their regular prices.',
    menuStep01_Business: 'Each menu should have a name, a start time, and an end time. Don\'t forget to go to Items and connect the appropriate items to this Menu. If a menu is active during its specified start and end times, the price set for items on that menu will take precedence over their regular prices. By default, all items are automatically added to a new menu until you manually deselect them in the Items section.',
    menuStep02___Wizard: 'The assigned color is only for the visual representation of the menus on the chart. Avoid menus overlapping.',
    menuStep02_Business: 'The assigned color is only for the visual representation of the menus on the chart. Avoid menus overlapping.',

    optionsDoThis___Wizard: 'Provide a title for each option. \nCheck the box if it\'s free, or uncheck it to set a price that will be added to the item\'s price when ordered. Later, you will be able to link your items to this option.',
    optionsDoThis_Business: 'Provide a title for each option. \nCheck the box if it\'s free, or uncheck it to set a price that will be added to the item\'s price when ordered. \nRemember to link this option to the relevant items needed.',

    groupOptionsStep01___Wizard: 'Provide a title for the group option.',  //Not in Wizard
    groupOptionsStep01_Business: 'Provide a title for the group option.',
    groupOptionsStep02___Wizard: 'Check the box if it\'s free, or uncheck it to set a price. \nBy default, the top option is auto-selected on the clients\' side. Drag and drop to rearrange the order of all options inside the group.', //Not in Wizard
    groupOptionsStep02_Business: 'Check the box if it\'s free, or uncheck it to set a price. \nBy default, the top option is auto-selected on the clients\' side. Drag and drop to rearrange the order of all options inside the group.',

    itemsStep01___Wizard: 'Keep the name brief and appealing. Prep Code is the abbreviated code used in the PREP APP (optional).',
    itemsStep01_Business: 'Keep the name brief and appealing. Prep Code is the abbreviated code used in the PREP APP (optional).',
    itemsStep02___Wizard: 'Pricing: Specify the listed price, and return here after creating the business to set the sale price and menu prices if necessary.',
    itemsStep02_Business: 'Remember the rule: A menu price is stronger than the listed price, and the sale price supersedes them both.',
    itemsStep03___Wizard: 'The Options section opens after the creation of the business.',
    itemsStep03_Business: 'Select which Options and Group Options are relevant for this item (multi-selection allowed)',
    itemsStep04___Wizard: 'This is optional but important! Please attach allergen icons (e.g., Sesame, Nuts, etc.) and labels (e.g., Vegan, Long Prep, Chef Special, etc.).',
    itemsStep04_Business: 'This is optional but important! Please attach allergen icons (e.g., Sesame, Nuts, etc.) and labels (e.g., Vegan, Long Prep, Chef Special, etc.).',
    itemsStep05___Wizard: 'Item description and image upload',
    itemsStep05_Business: 'Item description and image upload',
    itemsStep06___Wizard: 'Link this item to at least one Category (required) and to a Prep Station (optional)',
    itemsStep06_Business: 'Link this item to at least one Category (required) and to a Prep Station (optional)',
    itemsStep07___Wizard: 'The Special Preferences section opens after the creation of the business.',
    itemsStep07_Business: 'Preferences',

    },

  suggestions: {
    tables: [
      {
        ref: 'bar',
        title: 'בר',
        seats: 2,
        color: '#ff7700'
      },
      {
        ref: 'vip',
        title: 'VIP',
        seats: 12,
        color: '#ffaf00'
      },
      {
        ref: 'tb1',
        title: 'חוץ1',
        seats: 4,
        color: '#0c67e9',
        default: true
      },
      {
        ref: 'tb2',
        title: 'חוץ2',
        seats: 4,
        color: '#6f46a8'
      }
    ],
    extras: [
      {
        ref: 'noSalt',
        title: 'ללא מלח',
        price: 0,
      },
      {
        ref: 'noOnion',
        title: 'ללא בצל',
        price: 0,
      },
      {
        ref: 'extraKetchup',
        title: 'תוספת קטשופ',
        price: 0.5,
      },
      {
        ref: 'toGo',
        title: 'לארוז לקחת',
        price: 0,
      },
      // {
      // 	ref: 'preperation',
      // 	title: 'מידת עשייה',
      // 	price: 0,
      // 	extras_group: [
      // 		{
      // 			title: 'מדיום-רייר MR',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'מדיום',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'מדיום-וול MW',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'עשוי היטב WD',
      // 			price: 0
      // 		}
      // 	]
      // },
      // {
      // 	ref: 'sideDish',
      // 	title: 'בחר תוספת אחת מהרשימה',
      // 	price: 0,
      // 	extras_group: [
      // 		{
      // 			title: 'אורז',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'תפוחי אדמה',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'ספגאטי',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'קוסקוס',
      // 			price: 2.5
      // 		}
      // 	]
      // },

    ],
    items: [
      // {
      // 	ref: 'beer',
      // 	title: 'בירה מהחבית',
      // 	symbol: 'BRP',
      // 	description: 'A cold, refreshing pint of beer',
      // 	image: ItemImages[0],
      // 	price: '20',
      // 	kitchen_terminal: null,
      // 	take_away: true,
      // 	archive: false
      // },


      {
        ref: 'coffee',
        title: 'כוס קפה',
        symbol: 'COC',
        description: 'הקפה שלנו הוא ללא ספק הדרך הטובה ביותר להתחיל את היום, ואפשרויות ארוחת הבוקר והצהריים הטעימות והטריות שלנו ישביעו את התיאבון שלכם',
        image: ItemImages[15],
        price: '4.5',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      },
      {
        ref: 'burger',
        title: 'המבורגר הבית',
        symbol: 'CBG',
        description: 'לחמנייה רכה לחלוטין, בשר איכותי ועסיסי שמתובל היטב, בצל מקורמל ורוטב סודי ממש טוב הם המפתח להכנת המבורגר הבית הידוע שלנו',
        image: ItemImages[1],
        price: '7.99',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      },
      {
        ref: 'salad',
        title: 'סלט הבית',
        symbol: 'SLD',
        description: 'עגבניות שרי ועלים ירוקים פריכים',
        image: ItemImages[5],
        price: '25',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      },
      {
        ref: 'pizza',
        title: 'פיצה מוצרלה',
        symbol: 'MZP',
        description: 'פיצה מוצרלה קלאסית זו פשוטה וטעימה! עשויה עם מוצרלה טרייה, עגבניות ובזיליקום',
        image: ItemImages[4],
        price: '25',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      }
    ],
    categories: [
      {
        ref: 'burgers',
        title: 'המבורגר',
        color: '#ff7700',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_26.png',
        default: true
      },
      {
        ref: 'starters',
        title: 'מנות פתיחה',
        color: '#ffaf00',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_11.png',
      },
      {
        ref: 'sides',
        title: 'נישנושים',
        color: '#8c99a7',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_24.png',
        default: true
      },
      {
        ref: 'coldDrinks',
        title: 'שתיה קרה',
        color: '#00afd8',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_02.png',
        default: true
      }
    ],
    kitchens: [
      {
        ref: 'main',
        title: 'מטבח ראשי',
        color: '#003250',
        default: true,
        main: true
      },
      {
        ref: 'grill',
        title: 'פס חם',
        color: '#ff2644'
      },
      {
        ref: 'prep',
        title: 'פס קר',
        color: '#6f46a8'
      },
      {
        ref: 'bar',
        title: 'בר',
        color: '#00afd8'
      }
    ],
    menus: [
      {
        ref: 'main',
        title: 'תפריט ראשי',
        from: '00:01',
        to: '23:59',
        color: '#000000',
        items_with_special_price: {},
        default: true
      },
      {
        ref: 'breakfast',
        title: 'תפריט בוקר',
        from: '08:00',
        to: '11:00',
        color: '#00afd8',
        items_with_special_price: {}
      },
      {
        ref: 'lunch',
        title: 'תפריט צהריים',
        from: '12:00',
        to: '16:00',
        color: '#00a05b',
        items_with_special_price: {}
      },
      {
        ref: 'happyHour',
        title: 'תפריט Happy Hour',
        from: '17:00',
        to: '18:00',
        color: '#ff2644',
        items_with_special_price: {}
      },
      {
        ref: 'dinner',
        title: 'תפריט לילה',
        from: '18:01',
        to: '20:00',
        color: '#ff7700',
        items_with_special_price: {}
      }
    ]
  },

  currency: {
    name: 'Dollar',
    abv: 'USD',
    prefix: '$',
    suffix: '',
    make: '${num}',
  },
	currencies: {
		usd: { name: "U.S. Dollar (USD)", symbol: "$" },
		eur: { name: "Euro (EUR)", symbol: "€" },
		ils: { name: "שקל ישראלי", symbol: "₪" },
    gbp: { name: "Pound Sterling (GBP)", symbol: "£" }
	},
  models: {
    category: { singular: 'קטגוריה', plural: 'קטגוריות' },
    chef: { singular: 'צוות', plural: 'אנשי צוות' },
    extra: { singular: 'אופציה', plural: 'אופציות' },
    item: { singular: 'פריט', plural: 'פריטים' },
    kitchen: { singular: 'תחנת מטבח', plural: 'תחנות מטבח' },
    menu: { singular: 'תפריט', plural: 'תפריטים' },
    supervisor: { singular: 'אחמ\"ש', plural: 'אחמ\"שים' },
    table: { singular: 'שולחן', plural: 'שולחנות' },
    waiter: { singular: 'מלצר', plural: 'מלצרים' },
  },

}
